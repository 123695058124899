import React from 'react';
import { IRequirementsConfig, ProductionProductStore } from '../../stores/ProductionProductStore';
import DocCertificateStatus from './Documents/DocCertificatesStatus';
import { observer } from 'mobx-react-lite';
// Constants
export const DOC_STATUS_COLORS = {
    PENDING_UPLOAD: 'red',
    PENDING_APPROVAL: 'orange',
    APPROVED: 'green',
    OPTIONAL: 'default',
};

export const DOCUMENT_STATUS = {
    PENDING_UPLOAD: 'Pending Upload',
    PENDING_APPROVAL: 'Pending Approval',
    APPROVED: 'Approved',
    OPTIONAL_UPLOAD: 'Optional Upload',
    OPTIONAL_APPROVAL: 'Optional Approval',
};

// Utility functions
const getVendorDocumentStatus = (requirement: IRequirementsConfig, isUploaded: boolean, isApproved: boolean) => {
    if (!isUploaded) {
        return requirement.required_upload ? DOCUMENT_STATUS.PENDING_UPLOAD : DOCUMENT_STATUS.OPTIONAL_UPLOAD;
    }
    if (isApproved) {
        return DOCUMENT_STATUS.APPROVED;
    }
    return requirement.required_approval ? DOCUMENT_STATUS.PENDING_APPROVAL : DOCUMENT_STATUS.OPTIONAL_APPROVAL;
};

const getVendorDocumentBackgroundColor = (
    requirement: IRequirementsConfig,
    isUploaded: boolean,
    isApproved: boolean
) => {
    if (!isUploaded) {
        return requirement.required_upload ? DOC_STATUS_COLORS.PENDING_UPLOAD : DOC_STATUS_COLORS.OPTIONAL;
    }
    if (isApproved) {
        return DOC_STATUS_COLORS.APPROVED;
    }
    return requirement.required_approval ? DOC_STATUS_COLORS.PENDING_APPROVAL : DOC_STATUS_COLORS.OPTIONAL;
};

const getVendorDocumentTextColor = (requirement: IRequirementsConfig, isUploaded: boolean, isApproved: boolean) => {
    if (!isUploaded && !requirement.required_upload) {
        return 'black';
    }
    if (isApproved || (isUploaded && !requirement.required_approval)) {
        return 'black';
    }
    return '';
};

const renderStatus = (isUploaded: boolean, isApproved: boolean, requirement: IRequirementsConfig) => {
    const status = getVendorDocumentStatus(requirement, isUploaded, isApproved);
    const backgroundColor = getVendorDocumentBackgroundColor(requirement, isUploaded, isApproved);
    const textColor = getVendorDocumentTextColor(requirement, isUploaded, isApproved);

    return (
        <DocCertificateStatus
            isUploaded={isUploaded}
            isApproved={isApproved}
            requirementStatus={status}
            statusColor={backgroundColor}
            textColor={textColor}
        />
    );
};

interface Props {
    requirements: IRequirementsConfig[];
    isProductionTeam: boolean;
}

const RenderRequirements = (props: Props) => {
    const { requirements, isProductionTeam } = props;

    const currentUploads = ProductionProductStore.allProductDocumentsResponse.results;

    const data = requirements.map(requirement => {
        const isUploaded = currentUploads.some(doc => doc.document_type === requirement.requirement);
        const isApproved = currentUploads.some(doc => doc.document_type === requirement.requirement && doc.approved);

        return (
            <div key={requirement.requirement} style={{ display: 'flex', gap: '10px', marginBottom: '8px' }}>
                <div style={{ flex: 2, textAlign: 'right' }}>
                    {isProductionTeam
                        ? requirement.requirement
                        : `${requirement.requirement} ${
                              requirement.requirement === 'First Article Inspection' ? '- Vendor' : ''
                          }`}
                </div>
                <div style={{ flex: 1 }}>{renderStatus(isUploaded, isApproved, requirement)}</div>
            </div>
        );
    });

    return <>{data}</>;
};

export default observer(RenderRequirements);
