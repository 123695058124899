import React from 'react';
import { Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Text } = Typography;

const QuantityDisplay: React.FC<{ quantity: number; originalQuantity: number }> = ({ quantity, originalQuantity }) => {
    return (
        <span>
            {originalQuantity > 0 && originalQuantity > quantity ? (
                <>
                    <Text delete>{originalQuantity}</Text>
                    <Text>
                        <ArrowRightOutlined style={{ fontSize: '0.7rem' }} />
                    </Text>
                    <Text strong mark>
                        {quantity}
                    </Text>
                </>
            ) : (
                <Text>{quantity}</Text>
            )}
        </span>
    );
};

export default QuantityDisplay;
