import React from 'react';
import './App.css';
import Main from './components/MainComponent';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './stores/ReduxStore';
import {
    ThreeDViewer,
    ThreeDViewerForInstantQuote,
    ThreeDViewerFromStepFileURL,
    StaffThreeDViewerFromStepFile,
} from './pages/ThreeDViewer';
import { DrawingViewer } from './pages/Tools';
import LuckyOrangeScript from './components/LuckyOrangeScript';

const App = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <div className="App">
                    <LuckyOrangeScript />

                    <Switch>
                        <Route path="/3d-viewer/:mpId">
                            <ThreeDViewer />
                        </Route>
                        <Route path="/step-file-viewer/:id">
                            <StaffThreeDViewerFromStepFile />
                        </Route>

                        <Route path="/drawing-viewer/:drawing_file_id/" component={DrawingViewer} />

                        <Route path="/viewer/:quote_uuid/:item_uuid" component={ThreeDViewerForInstantQuote} />

                        <Route path="/viewer/:id/" component={ThreeDViewerFromStepFileURL} />

                        <Route path="/staff-threedviewer/:id/" component={StaffThreeDViewerFromStepFile} />

                        <Route path="/">
                            <Main />
                        </Route>
                    </Switch>
                </div>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
