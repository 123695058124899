import React, { useEffect } from 'react';
import { ISalesOrderLineItemResponse } from '../../stores/SalesOrderLineItemStore';
import { BadgerTable } from '../badger-ui';
import { ColumnProps } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { IInvitedVendorsToBid, IProductBids, ProductionProductStore } from '../../stores/ProductionProductStore';
import { Price } from '@partsbadger/library';
import { List, Card, Row, Col, Divider, Tag } from 'antd';
import ProductBiddingStatus from './ProductBiddingStatus';

interface Props {
    entity: ISalesOrderLineItemResponse;
}

const ProductionBidsByProduct = (props: Props) => {
    const { entity } = props;

    const { allProductBidsResponse, allInvitedVendorsToBidResponse } = ProductionProductStore;

    const bids = allProductBidsResponse.results;
    const totalRecords = allProductBidsResponse.count;

    const invitedVendors = allInvitedVendorsToBidResponse.results;

    useEffect(() => {
        if (entity.product_zid) {
            ProductionProductStore.getBidsByProductId(entity.product_zid);
            ProductionProductStore.getInvitedVendorsToBidByProductId(entity.product_zid);
        }
    }, [entity.product_zid]);

    const columns: ColumnProps<IProductBids>[] = [
        {
            title: 'Bid Number',
            dataIndex: 'bid',
        },
        {
            title: 'Bidding date',
            dataIndex: 'created',
            render: (value, r) => {
                return new Date(value).toLocaleDateString();
            },
        },

        {
            title: 'Vendor',
            dataIndex: 'vendor',
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            render: value => <Price value={value} />,
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            render: value => <Price value={value} />,
        },

        {
            title: 'Status',
            dataIndex: 'status',
            render: value => {
                return <ProductBiddingStatus status={value} />;
            },
        },
        {
            title: 'Vendor Confirmation Date',
            dataIndex: 'vendor_confirmation_date',
            render: value => {
                if (value) {
                    return new Date(value).toLocaleDateString();
                }
                return 'N/A';
            },
        },
    ];

    const hasBid = (vendorName: string): boolean => {
        return bids.some(bid => bid.vendor === vendorName);
    };

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card title="Invited Vendors" bordered>
                        {invitedVendors.length > 0 ? (
                            <List<IInvitedVendorsToBid>
                                dataSource={invitedVendors}
                                renderItem={item => (
                                    <List.Item>
                                        <div>
                                            <strong>{item.vendor}</strong> (
                                            {new Date(item.created).toLocaleDateString()})
                                            {hasBid(item.vendor) ? (
                                                <Tag color="green" style={{ marginLeft: 8 }}>
                                                    Bid Submitted
                                                </Tag>
                                            ) : (
                                                <Tag color="red" style={{ marginLeft: 8 }}>
                                                    No Bid
                                                </Tag>
                                            )}
                                        </div>
                                    </List.Item>
                                )}
                                size="small"
                            />
                        ) : (
                            <p>No invited vendors available.</p>
                        )}
                    </Card>
                </Col>
            </Row>

            <Divider />

            <BadgerTable<IProductBids>
                dataSource={bids}
                columns={columns}
                pagination={{
                    totalRecords: totalRecords,
                    pageSize: 30,
                }}
            />
        </>
    );
};

export default observer(ProductionBidsByProduct);
