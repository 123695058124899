import React, { useEffect } from 'react';
import { ISalesOrderLineItemResponse } from '../../stores/SalesOrderLineItemStore';
import { BadgerTable } from '../badger-ui';
import { ColumnProps } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import {
    IProductBids,
    IProductBidsResponse,
    IProductDocument,
    IProductShipment,
    ProductionProductStore,
} from '../../stores/ProductionProductStore';
import { Price } from '@partsbadger/library';
import { CheckOutlined } from '@ant-design/icons';

interface Props {
    entity: ISalesOrderLineItemResponse;
}

const ProductionDocumentsByProduct = (props: Props) => {
    const { entity } = props;

    const { allProductDocumentsResponse } = ProductionProductStore;

    const bids = allProductDocumentsResponse.results;
    const totalRecords = allProductDocumentsResponse.count;

    useEffect(() => {
        if (entity.product_zid) {
            ProductionProductStore.getDocumentsByProductId(entity.product_zid);
        }
    }, [entity.product_zid]);

    const columns: ColumnProps<IProductDocument>[] = [
        {
            title: 'Document Type',
            dataIndex: 'document_type',
        },
        {
            title: 'Name',
            dataIndex: 'key',
        },
        // {
        //     title: 'Certificate Number',
        //     dataIndex: 'certificate_number',
        // },
        {
            title: 'Approved',
            dataIndex: 'approved',
            render: (approved: boolean) => (approved ? <CheckOutlined style={{ color: 'green' }} /> : null),
        },
    ];
    return (
        <BadgerTable<IProductDocument>
            dataSource={bids}
            columns={columns}
            pagination={{
                totalRecords: totalRecords,
                pageSize: 30,
            }}
        />
    );
};

export default observer(ProductionDocumentsByProduct);
