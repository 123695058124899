import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Skeleton, Typography, Tag, Divider, Alert, Drawer, Image } from 'antd';
import { BadgerPage } from '../../components/badger-ui';

import { QuoteStore } from '../../stores';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { get, URL_PORTAL } from '@partsbadger/utils';
import FileDownload from 'js-file-download';
import { Price, DateRender } from '@partsbadger/library';
import { SalesOrderStore } from '@partsbadger/sales_order_tool';
import { DownloadOutlined, FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import { QuoteNotifications } from '@partsbadger/quote-tool/lib/components';
import { API_ROOT as BASE_URL } from '@partsbadger/utils/lib/conf/settings';
const { Title, Text, Link } = Typography;

const TextItem = ({ label, value }: { label: string; value: string | number | JSX.Element }) => (
    <div>
        <Text strong>{label}:</Text> {value}
    </div>
);

const QuoteDetails = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const [isConvertingToSalesOrder, setIsConvertingToSalesOrder] = useState(false);
    const [showQuoteNotificationsDrawer, SetShowQuoteNotificationsDrawer] = useState<boolean>(false);
    const [duplicating, setDuplicating] = useState(false);
    const [DownloadingFiles, setDownloadingFiles] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            QuoteStore.getById(Number(id));
        }
        return () => {};
    }, [id]);

    const downloadQuote = (name: string, id: number) => {
        const url = `/staff/quotes/${id}/export/`;
        get(url, { responseType: 'blob' })
            .then(data => {
                FileDownload(data, name + '.pdf');
            })
            .catch(error => {});
    };

    const handleDownload = (fileUrl: string) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.download = ''; // Optionally, specify a default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleClickCheckout = async (quote_id: number, contact_id: number) => {
        await SalesOrderStore.getOrCreateSalesOrder(contact_id).then(async () => {
            await SalesOrderStore.update(SalesOrderStore.order_id, {
                quotes: [],
            });
            await SalesOrderStore.update(SalesOrderStore.order_id, {
                contact: contact_id,
                quotes: [quote_id],
            });
            history.push('/sales-order/new/');
        });
    };

    const { entity, loading } = QuoteStore;

    const isDisabled = entity?.stage != 'Completed';

    const quote = entity;

    if (!quote) {
        return <Skeleton active />;
    }

    return (
        <BadgerPage
            title={<div>{quote.name}</div>}
            subtitle={<Tag color={quote.stage == 'Completed' ? 'green' : 'warning'}> {quote.stage}</Tag>}
            handleBack={() => history.goBack()}
            isLoading={loading}
            topActions={[
                <Button
                    key="dynamic-quote"
                    type="primary"
                    disabled={isDisabled}
                    onClick={() => window.open(`${URL_PORTAL}/my-account/quotes/${quote.id}`)}
                >
                    Create Dynamic Quote
                </Button>,

                <Button
                    key="1"
                    type="default"
                    disabled={isDisabled}
                    loading={duplicating}
                    onClick={
                        () => {
                            setDuplicating(true);
                            QuoteStore.getRequote(quote.id)
                                .then((r: any) => {
                                    setDuplicating(false);
                                    history.push('/get-quote/?uid=' + r.data.uid);
                                })
                                .catch(() => setDuplicating(false));
                        }
                        // history.push(`/accounts/${quote.contact?.account?.id}/?search=${quote.name}&page=1#Parts`)
                    }
                >
                    Requote
                </Button>,

                <Button
                    disabled={isDisabled}
                    key="notifications"
                    type="default"
                    onClick={() => SetShowQuoteNotificationsDrawer(true)}
                >
                    Notifications
                </Button>,
                <Button
                    disabled={isDisabled}
                    key="download-pdf"
                    type="default"
                    onClick={() => downloadQuote(quote.name, quote.id)}
                >
                    <FilePdfOutlined />
                    Download
                </Button>,
                <Button
                    key={'download-all'}
                    loading={DownloadingFiles}
                    disabled={DownloadingFiles}
                    onClick={async () => {
                        try {
                            setDownloadingFiles(true);
                            await QuoteStore.downloadAllQuoteFiles(quote);
                        } catch (error) {
                            setDownloadingFiles(false);
                        } finally {
                            setDownloadingFiles(false);
                        }
                    }}
                >
                    <DownloadOutlined /> Download Files
                </Button>,
            ]}
        >
            <Row gutter={[16, 16]}>
                {isDisabled && (
                    <Col xs={24} xl={24}>
                        <Card bordered>
                            <Alert
                                message="This quote is not ready"
                                description="This quote has not been completed or has been canceled. Please verify the current status of the quote."
                                type="warning"
                                showIcon
                            />
                        </Card>
                    </Col>
                )}
                {/* Quote Information */}
                <Col xs={24} xl={16}>
                    <Card bordered className="shadow-sm mb-3 p-4">
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <TextItem label="Domestic" value={quote.domestic_quoting ? 'Yes' : 'No'} />
                            </Col>
                            <Col span={8}>
                                <TextItem label="Type" value={quote.type} />
                            </Col>
                            <Col span={8}>
                                <TextItem label="Grand Total" value={<Price value={quote.grand_total} />} />
                            </Col>
                        </Row>
                    </Card>

                    <Col xs={24} xl={24}>
                        <Card bordered className="shadow-sm mb-3">
                            {quote.line_items.map((item, index) => (
                                <div key={item.id} style={{ paddingTop: '0.5rem' }}>
                                    <Row
                                        align="middle"
                                        className="item-row mb-3"
                                        style={{ padding: '1rem', borderBottom: '1px solid #eaeaea' }}
                                    >
                                        <Col style={{ marginRight: '1rem' }}>
                                            <div style={{ width: 60 }}>
                                                <Image
                                                    alt="Part Thumbnail"
                                                    src={item.master_product?.image}
                                                    style={{ width: '100%' }}
                                                    preview={true}
                                                    // fallback="path/to/placeholder-image.png" // Fallback image
                                                />
                                            </div>
                                        </Col>
                                        <Col flex="auto">
                                            <Title level={5} style={{ marginBottom: '0.5rem' }}>
                                                {item.name}
                                            </Title>

                                            <Row gutter={16} style={{ marginTop: '1rem' }}>
                                                <Col flex="260px" className="mb-3">
                                                    <div className="details-section" style={{ lineHeight: 1.5 }}>
                                                        <Text strong style={{ color: '#333' }}>
                                                            Material:
                                                        </Text>{' '}
                                                        <Text>{item.material?.name}</Text>
                                                        <br />
                                                        <Text strong style={{ color: '#333' }}>
                                                            Surface Finish:
                                                        </Text>{' '}
                                                        <Text>{item.finish?.name}</Text>
                                                        <br />
                                                        <Text strong style={{ color: '#333' }}>
                                                            Coating:
                                                        </Text>{' '}
                                                        <Text>{item.coating?.name ?? 'N/A'}</Text>
                                                        <div>
                                                            <Typography.Link
                                                                disabled={!item.master_product.drawing_file}
                                                                onClick={() => {
                                                                    if (item.master_product.drawing_file) {
                                                                        handleDownload(
                                                                            item.master_product.drawing_file
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <FilePdfOutlined /> 2D
                                                            </Typography.Link>

                                                            <Link
                                                                disabled={!item.master_product.step_file}
                                                                style={{
                                                                    marginLeft: '1rem',
                                                                }}
                                                                onClick={() => {
                                                                    if (item.master_product.step_file) {
                                                                        handleDownload(item.master_product.step_file);
                                                                    }
                                                                }}
                                                            >
                                                                <FileTextOutlined /> 3D
                                                            </Link>
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <Text strong style={{ color: 'red', fontStyle: 'italic' }}>
                                                                {item.master_product?.deviation_note}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col flex="auto" className="mb-3">
                                                    <Row justify="space-around" align="middle" className="text-center">
                                                        <Col span={6}>
                                                            <Text strong style={{ color: '#333' }}>
                                                                Lead Time
                                                            </Text>
                                                            <div
                                                                style={{
                                                                    fontSize: '1rem',
                                                                    color: '#555',
                                                                }}
                                                            >
                                                                {item.lead_time} days
                                                            </div>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Text strong style={{ color: '#333' }}>
                                                                Quantity
                                                            </Text>
                                                            <div
                                                                style={{
                                                                    fontSize: '1rem',
                                                                    color: '#555',
                                                                }}
                                                            >
                                                                {item.quantity}
                                                            </div>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Text strong style={{ color: '#333' }}>
                                                                Unit Price
                                                            </Text>
                                                            <div
                                                                style={{
                                                                    fontSize: '1rem',
                                                                    color: '#555',
                                                                }}
                                                            >
                                                                <Price value={item.unit_price} />
                                                            </div>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Text strong style={{ color: '#333' }}>
                                                                Total Price
                                                            </Text>
                                                            <div
                                                                style={{
                                                                    fontSize: '1rem',
                                                                    color: '#555',
                                                                }}
                                                            >
                                                                <Price value={item.total_price} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </Card>
                    </Col>
                </Col>

                {/* Additional Requirements */}
                <Col xs={24} xl={8}>
                    <Card bordered>
                        <Title level={4}>Quote Information</Title>
                        <Text strong>RFQ Number:</Text> {quote.rfq || 'N/A'}
                        <br />
                        <Text strong>Submitted By:</Text> {quote.created_by?.fullname || 'N/A'}
                        <br />
                        <Text strong>Quoted By:</Text> {quote.design_engineer?.fullname || 'N/A'}
                        <span>
                            &nbsp; - <DateRender value={quote.completed_at} />
                        </span>
                        <br />
                    </Card>

                    <br />
                    {/* Contact Information */}
                    <Card bordered>
                        <Title level={4}>Contact Information</Title>
                        <Text strong>Name:</Text> {quote.contact?.first_name} {quote.contact?.last_name}
                        <br />
                        <Text strong>Email:</Text> {quote.contact?.email}
                        <br />
                    </Card>

                    <br />

                    <Card bordered>
                        <Title level={4}>Quote Notes:</Title>
                        <Text>
                            <div dangerouslySetInnerHTML={{ __html: quote.notes ?? '--' }} />
                        </Text>
                    </Card>

                    <br />

                    <Card bordered>
                        <Title level={4}>Quote includes:</Title>
                        {quote.custom_requirements.map(requirement => (
                            <Text key={requirement.key}>{requirement.label}, </Text>
                        ))}
                    </Card>

                    <br />

                    <Card bordered>
                        <Title level={4}>Additional Costs:</Title>
                        <Row justify="space-between">
                            <Text>Subtotal</Text>
                            <Price value={quote.grand_total} />
                        </Row>
                        <Row justify="space-between">
                            <Text>Application Cost</Text>
                            <Price value={quote.application_cost} />
                        </Row>
                        <Row justify="space-between">
                            <Text>Discount</Text>
                            <Price value={quote.discount} />
                        </Row>
                        <Row justify="space-between">
                            <Text strong>Estimated Sales Tax %({quote.tax_rate ?? 0}):</Text>
                            <Price value={quote.taxes} />
                        </Row>
                        <Row justify="space-between" style={{ borderTop: '1px solid #d9d9d9', paddingTop: '1rem' }}>
                            <Text strong style={{ fontSize: '16px' }}>
                                Grand Total
                            </Text>
                            <Text style={{ fontSize: '16px' }}>
                                <Price value={quote.grand_total_with_shipping_cost} />
                            </Text>
                        </Row>

                        <br />

                        <Button
                            disabled={isDisabled}
                            type="primary"
                            size="large"
                            loading={isConvertingToSalesOrder}
                            block
                            onClick={() => {
                                history.push('/sales-order/new');
                                setIsConvertingToSalesOrder(true);
                                handleClickCheckout(quote.id, quote.contact?.id).finally(() => {
                                    setIsConvertingToSalesOrder(false);
                                });
                            }}
                        >
                            Continue to Checkout
                        </Button>
                    </Card>
                </Col>
            </Row>

            <Drawer
                title="Notifications"
                placement="right"
                closable={true}
                width={1200}
                height={500}
                onClose={() => SetShowQuoteNotificationsDrawer(false)}
                visible={showQuoteNotificationsDrawer}
            >
                {showQuoteNotificationsDrawer && <QuoteNotifications height={600} />}
            </Drawer>
        </BadgerPage>
    );
};

export default observer(QuoteDetails);
