import React from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { SalesOrderProps } from '@partsbadger/types';
import { AddressProps } from '@partsbadger/library';
import { CalendarOutlined, NumberOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
import SalesOrderAddresses from './SalesOrderAddresses';
import { useHistory } from 'react-router-dom';

interface Props {
    order: SalesOrderProps;
}
const { Text, Link } = Typography;

const SalesOrderDetailHeader = ({ order }: Props) => {
    const history = useHistory();

    return (
        <div className="order-header">
            <Row gutter={[16, 16]} align="middle">
                <Col xs={24} md={12}>
                    <Text strong>
                        <NumberOutlined /> PO Number:
                    </Text>{' '}
                    <Text>{order.purchase_order || '--'}</Text>
                </Col>
                <Col xs={24} md={12}>
                    <Text strong>
                        <CalendarOutlined /> Ship Date:
                    </Text>{' '}
                    <Text>{order.required_ship_date || 'In Review'}</Text>
                </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>
                <Col xs={24} md={12}>
                    <Text strong>
                        <UserOutlined /> Account:
                    </Text>{' '}
                    <Link
                        onClick={() => {
                            history.push(`/accounts/${order.account?.id}`);
                        }}
                    >
                        {order.account?.name || '-'}
                    </Link>
                </Col>
                <Col xs={24} md={12}>
                    <Text strong>
                        <UserOutlined /> Contact:
                    </Text>
                    <Link
                        onClick={() => {
                            history.push(`/contacts/${order.contact?.id}`);
                        }}
                    >
                        {order.contact?.fullname || ''} - {order.contact?.email || ''}
                    </Link>
                    <Text></Text>
                </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>
                <Col xs={24} md={12}>
                    <Text strong>
                        <UserOutlined /> Type:
                    </Text>{' '}
                    <Text>{order.type}</Text>
                </Col>
                <Col xs={24} md={12}>
                    <Text strong>
                        <UserOutlined /> Deal:
                    </Text>{' '}
                    <Link
                        onClick={() => {
                            history.push(`/deals/${order.deal}`);
                        }}
                    >
                        {order.deal_related?.name || '-'}
                    </Link>
                </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>
                <Col xs={24} md={12}>
                    <Text strong>
                        <WalletOutlined /> Payment Terms:
                    </Text>{' '}
                    <Text>{order.payment_terms || '-'}</Text>
                </Col>
                <Col xs={24} md={12}>
                    <Text strong>
                        <WalletOutlined /> Payment Info:
                    </Text>{' '}
                    <Text>{order.payment_notes || '-'}</Text>
                </Col>
            </Row>
            {/*<Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>*/}
            {/*    <Col xs={24} md={12}>*/}
            {/*        <Text strong>*/}
            {/*            <WalletOutlined /> Submitter:*/}
            {/*        </Text>{' '}*/}
            {/*        <Text>{order.source || '-'}</Text>*/}
            {/*    </Col>*/}
            {/*    <Col xs={24} md={12}></Col>*/}
            {/*</Row>*/}

            <Divider />
        </div>
    );
};

export default SalesOrderDetailHeader;
