import { action, observable, runInAction, makeObservable } from 'mobx';
import { get, patch, post, remove } from '@partsbadger/utils';
import { ICreateSalesOrderRework, IInvoiceDetailStaff, OrderLineItemProps, SalesOrderProps } from '@partsbadger/types';
import { INotes, IQualityDocumentsProps, IShipmentProps, VendorProductsProps } from '../../utils/types';
import { AccountProps, ContactProps } from '@partsbadger/sales_order_tool/lib/types/types';
import { AddressProps } from '@partsbadger/library';
import { openNotificationWithIcon } from '@partsbadger/shipping_receiving_v2/lib/Stores';
import { getFile } from '@partsbadger/shipping_receiving_v2/lib/utils/api';
import fileDownload from 'js-file-download';
import { IQuoteListStaff, ISalesOrderListStaff } from '@partsbadger/types';
import axios from 'axios';

interface IRequestUpdate {
    username: string;
    first_name: string;
    last_name: string;
    company_name: string;
    order: string | undefined;
    email: string;
    notes: string;
}

interface IProfile {
    id: number;
    name: string;
    url: string;
}

export interface IRedactedProfile {
    id: number;
    account: number;
    profile_name: string;
    settings: Setting[];
    thumbnail: null | string;
}

export interface Setting {
    text: string;
    corners: Corner[];
    page_number: number;
    redaction_type: RedactionType;
    font_size?: string;
}

export interface Corner {
    x: number;
    y: number;
}

export enum RedactionType {
    DrawingTextBox = 'DrawingTextBox',
    PatchCustomerInformation = 'PatchCustomerInformation',
    RemoveCustomerInformation = 'RemoveCustomerInformation',
}

function dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
}

class SalesOrder {
    loading = false;
    entities: ISalesOrderListStaff[] = [];
    order?: SalesOrderProps | null = null;
    cloneOrder?: SalesOrderProps | null = null;
    redactedProfiles: IRedactedProfile[] = [];
    quality_documents: IQualityDocumentsProps[] = [];
    products_tracking_numbers: Record<string, VendorProductsProps[]> = {};
    notes: {
        data: INotes[];
        pagination: {
            current: number;
            defaultPageSize: number;
            pageSize: number;
            total: number;
        };
    } = {
        data: [],
        pagination: {
            current: 1,
            defaultPageSize: 30,
            pageSize: 30,
            total: 0,
        },
    };

    notifications: any[] = [];
    shipments: IShipmentProps[] = [];
    account?: AccountProps | null = null;
    contact?: ContactProps | null = null;
    addresses: Array<AddressProps> = [];
    invoices: Array<IInvoiceDetailStaff> = [];
    savedRedactions: Array<any> = [];
    thumbnails?: any[] = [];
    isConnectedToSocket?: boolean = false;
    token: string | null = '';

    pagination: {
        current: number;
        defaultPageSize: number;
        pageSize: number;
        total: number;
    } = {
        current: 1,
        defaultPageSize: 30,
        pageSize: 30,
        total: 0,
    };

    constructor() {
        makeObservable(this, {
            // Observables

            loading: observable,
            entities: observable,
            pagination: observable,
            order: observable,
            cloneOrder: observable,
            redactedProfiles: observable,
            quality_documents: observable,
            products_tracking_numbers: observable,
            notes: observable,
            notifications: observable,
            shipments: observable,
            account: observable,
            contact: observable,
            addresses: observable,
            invoices: observable,
            savedRedactions: observable,
            thumbnails: observable,
            isConnectedToSocket: observable,
            token: observable,

            // Actions
            createCloneOrder: action,
            removeCloneOrderItem: action,
            updateCloneOrder: action,
            setCloneOrder: action,
            getById: action,
            getAccountRedactedProfiles: action,
            deleteAccountRedactedProfile: action,
            getQualityDocumentsBySalesOrderId: action,
            getTrackingNumbersBySalesOrderId: action,
            getPackListBySalesOrderId: action,
            getNotifications: action,
            getNotes: action,
            getShipmentsBySalesOrderId: action,
            getContactById: action,
            saveAddress: action,
            sendUpdateRequest: action,
            getInvoiceList: action,
            createNoteByItem: action,
            getOrderById: action,
            update: action,
            redactFile: action,
            uploadRedactedFile: action,
            saveRedactionProfile: action,
            applyRedactionProfile: action,
            getThumbnails: action,
            getThumbnailsFromProduct: action,
            resetRedactionDefaultProfile: action,
            updateItem: action,
            getVendorQualificationsFromVendorApp: action,
            notify_shipping_team: action,
            sendRedactedFilesToVendorAPP: action,
            sendToSalesRepresentative: action,
            setConnectedSocket: action,
            setToken: action,
        });
    }

    async removeCloneOrderItem(id: number) {
        const lineItems = this.cloneOrder?.line_items.filter(current => id !== current.id);

        runInAction(() => {
            if (this.cloneOrder && lineItems) {
                this.cloneOrder.line_items = lineItems;
            }
        });
    }

    updateCloneOrder(order_line_item: OrderLineItemProps) {
        const lineItems = this.cloneOrder?.line_items?.map(current => {
            if (order_line_item.id === current.id) {
                return order_line_item;
            }
            return current;
        });

        runInAction(() => {
            if (this.cloneOrder && lineItems) {
                this.cloneOrder.line_items = lineItems;
            }
        });
    }
    async setCloneOrder() {
        runInAction(() => {
            if (this.order) {
                this.cloneOrder = { ...this.order };
            }
        });
    }

    async createCloneOrder(id: number | string, payload = {}) {
        runInAction(() => {
            this.loading = true;
        });

        const data = await post(`/staff/sales-orders/${id}/clone/`, payload);

        runInAction(() => {
            this.loading = false;
        });

        return data;
    }

    async getById(id: number | string) {
        runInAction(() => {
            this.loading = true;
        });

        const data = await get(`/staff/sales-orders/${id}/`);

        runInAction(() => {
            this.order = data;
            this.loading = false;
        });

        return data;
    }

    async getByAccount(account_id: number, params: any) {
        runInAction(() => {
            this.loading = true;
        });

        const data = await get(`/staff/sales-orders/`, {
            params: {
                account: account_id,
                ...params,
            },
        });

        runInAction(() => {
            this.entities = data.results;
            this.loading = false;
            this.pagination.current = params.page;
            this.pagination.total = data.count;
        });
    }

    async getAccountRedactedProfiles(account_id: number) {
        this.redactedProfiles = await get(`/staff/accounts/${account_id}/redacted-profiles/`);
    }

    async deleteAccountRedactedProfile(account_id: number, redacted_profile_id: number) {
        runInAction(() => {
            this.redactedProfiles = this.redactedProfiles.filter(r => r.id != redacted_profile_id);
        });
        await remove(`/staff/accounts/${account_id}/redacted-profiles/${redacted_profile_id}/`);

        this.getAccountRedactedProfiles(account_id);
    }

    async getQualityDocumentsBySalesOrderId(sales_order_id: number | string) {
        await get(`/staff/sales-orders/${sales_order_id}/quality-documents/`).then(data => {
            runInAction(() => {
                this.quality_documents = data;
            });
        });
    }

    async getTrackingNumbersBySalesOrderId(sales_order_id: number) {
        get(`/staff/sales-orders/${sales_order_id}/tracking-numbers/`).then((data: VendorProductsProps[]) => {
            runInAction(() => {
                this.products_tracking_numbers = data.reduce((acc, item) => {
                    const { zoho_id } = item;
                    if (!acc[zoho_id]) {
                        acc[zoho_id] = [];
                    }
                    acc[zoho_id].push(item);
                    return acc;
                }, {} as Record<string, VendorProductsProps[]>);
            });
        });
    }

    async getPackListBySalesOrderId(sales_order_id: number, box_id: number) {
        return await get(`/staff/sales-orders/${sales_order_id}/download-packing-slip/${box_id}/`, {
            responseType: 'blob',
        });
    }

    async getNotifications(sales_order_id: string | number) {
        runInAction(() => {
            this.loading = true;
        });

        await get(`/staff/sales-orders/${sales_order_id}/notifications/`)
            .then(data => {
                runInAction(async () => {
                    this.notifications = await data;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }

    async getNotes(sales_order_id: string | number, page = 1, pageSize = 10) {
        runInAction(() => {
            //             this.loading = true;
        });

        try {
            const response = await get(
                `/staff/sales-orders/${sales_order_id}/notes/?page=${page}&page_size=${pageSize}`
            );

            runInAction(() => {
                // Assuming the API response contains pagination metadata
                const { results, count } = response;

                this.notes = {
                    data: results,
                    pagination: {
                        current: page,
                        defaultPageSize: pageSize,
                        pageSize: pageSize,
                        total: count,
                    },
                };
            });
        } catch (error) {
            console.error('Failed to fetch notes:', error);
            runInAction(() => {
                this.notes = {
                    data: [],
                    pagination: {
                        current: 0,
                        defaultPageSize: pageSize,
                        pageSize: pageSize,
                        total: 0,
                    },
                };
            });
        } finally {
            runInAction(() => {
                //                 this.loading = false;
            });
        }
    }

    async createSalesOrderNote(sales_order_id: string | number, payload: { content: string; title: string }) {
        try {
            const response = await post(`/staff/sales-orders/${sales_order_id}/notes/`, payload);
            runInAction(() => {
                this.notes.data = [response, ...this.notes.data];
            });
        } catch (error) {
            console.error('Failed to create note:', error);
        }
    }

    async getShipmentsBySalesOrderId(sales_order_id: number | string) {
        runInAction(() => {
            this.loading = true;
        });

        await get(`/staff/sales-orders/${sales_order_id}/shipments/`)
            .then(data => {
                runInAction(() => {
                    this.shipments = data.results;
                    this.loading = false;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }

    getContactById = (contact_id: number | string | undefined) => {
        get(`/customer/contacts/${contact_id}/`).then(data => {
            this.contact = data;
            this.addresses = this.contact?.addresses ?? [];
        });
    };

    saveAddress(contact_id: number | undefined, payload = {}) {
        return post(`/customer/contacts/${contact_id}/addresses/`, payload);
    }

    setConnectedSocket(connected: boolean) {
        this.isConnectedToSocket = connected;
    }

    setToken(token: string) {
        runInAction(() => {
            this.token = token;
        });
    }

    async sendUpdateRequest(id: number, data: IRequestUpdate) {
        runInAction(() => {
            this.loading = true;
        });

        await post(`/staff/sales-orders/${id}/send-update-request/`, data).then(() => {
            runInAction(() => {
                this.loading = false;
            });
        });
    }

    getInvoiceList = async (params: any) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await get(`/staff/invoices/`, { params: params });

        runInAction(() => {
            this.invoices = response.results;
            this.loading = false;
        });

        return response.results;
    };

    async createNoteByItem(item_id: number | string, payload = {}) {
        return await post(`/staff/sales-orders/${item_id}/add-note/`, payload);
    }

    setOrder(data?: SalesOrderProps) {
        runInAction(() => {
            this.order = data;
            this.loading = false;
        });
    }

    async getOrderById(sales_order_id: number) {
        this.loading = true;

        await get(`/staff/sales-orders/${sales_order_id}/`)
            .then(data => {
                this.setOrder(data);
                if (data.contact?.id) {
                    this.getContactById(data?.contact.id);
                }
            })
            .finally(() => (this.loading = false));
    }

    async update(order_id: number, payload: any) {
        this.loading = true;

        return patch(`/staff/sales-orders/${order_id}/`, payload).finally(() => {
            this.loading = false;
            this.getOrderById(order_id);
        });
    }

    redactFile = async (id: number, params: any) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await post(`/staff/drawing-files/${id}/redact-file/`, params).then(data => {
            runInAction(() => {
                this.loading = false;
                this.savedRedactions = [...this.savedRedactions, ...params.redactions];
            });

            return data;
        });

        return response;
    };

    uploadRedactedFile = async (id: number) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await post(`/staff/drawing-files/${id}/upload-redacted-file/`).then(data => {
            runInAction(() => {
                this.loading = false;
            });

            return data;
        });

        if (this.order?.id) {
            this.getOrderById(this.order?.id);
        }

        return response;
    };

    saveRedactionProfile = async (id: number, params: any) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await post(`/staff/drawing-files/${id}/save-profile-redactions/`, params).then(data => {
            runInAction(() => {
                this.loading = false;
            });

            return data;
        });

        return response;
    };

    applyRedactionProfile = async (id: number, params: any) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await post(`/staff/drawing-files/${id}/apply-profile-redactions/`, params).then(data => {
            runInAction(() => {
                this.loading = false;
                this.savedRedactions = [];
            });

            return data;
        });

        return response;
    };

    getThumbnails = async (id: number, line_item_id: any) => {
        const params = {
            account: id,
        };

        let getThumbnails = <Array<IProfile>>[];

        const thumbnails = await post(`/staff/drawing-files/${line_item_id}/get-thumbnails-from-pdf/`, params).then(
            data => {
                runInAction(() => {
                    this.thumbnails = data;
                    this.loading = false;
                });

                return data.map((data: { name: any; url: any }) => ({
                    id: line_item_id,
                    name: data.name,
                    url: data.url,
                }));
            }
        );

        getThumbnails = thumbnails;

        return thumbnails;
    };

    getThumbnailsFromProduct = async (id: number, line_items: any) => {
        const params = {
            account: id,
        };

        let getThumbnails = <Array<IProfile>>[];

        for (const line_item of line_items) {
            const thumbnails = await post(
                `/staff/drawing-files/${line_item?.production_2d_file?.id}/get-thumbnails-from-product/`,
                params
            ).then(data => {
                runInAction(() => {
                    this.thumbnails = data;
                    this.loading = false;
                });

                return data.map((data: { name: any; url: any }) => ({
                    id: line_item?.production_2d_file?.id,
                    name: data.name,
                    url: data.url,
                }));
            });

            if (getThumbnails.length > 0) {
                getThumbnails = [...getThumbnails, ...thumbnails];
            } else {
                getThumbnails = thumbnails;
            }
        }

        return getThumbnails;
    };

    resetRedactionDefaultProfile = async (id: number) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await post(`/staff/drawing-files/${id}/reset-redactions/`).then(data => {
            runInAction(() => {
                this.loading = false;
                this.savedRedactions = [];
            });

            return data;
        });

        if (this.order?.id) {
            this.getOrderById(this.order?.id);
        }

        return response;
    };

    updateItem = async (orderId: number, payload: any) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await patch(`/staff/sales-orders/${orderId}/update-item/`, payload).then(data => {
            runInAction(() => {
                this.order = data;
                this.loading = false;
            });

            return data;
        });

        return response;
    };

    // get qualifications from vendor app

    getVendorQualificationsFromVendorApp(sales_order_id: number) {
        return get(`/staff/sales-orders/${sales_order_id}/vendor-qualifications/`);
    }

    // Send redacted files to vendors

    sendRedactedFilesToVendorAPP(sales_order_id: number) {
        return get(`/staff/sales-orders/${sales_order_id}/send-redacted-files-to-vendor-app/`);
    }

    notify_shipping_team = async (payload: any) => {
        const formData = new FormData();
        if (payload.type_message) {
            formData.append('type_message', payload.type_message);
        }
        if (payload.notes) {
            formData.append('notes', payload.notes);
        }
        if (payload.files) {
            payload.files.forEach((item: any) => {
                formData.append('files', item);
            });
        }

        return await post(`/staff/sales-orders/${payload.id}/notify_shipping_team/`, formData, {}, true);
    };

    async downloadPdf(url: string, name_file: string) {
        openNotificationWithIcon('info', 'Generating file', 'Be patient');

        getFile(url)
            .then(async response => {
                if (response.ok) {
                    const myBlob = await response.blob();
                    return {
                        blob: myBlob,
                    };
                } else {
                    const myJson = await response.json();
                    return {
                        json: myJson,
                    };
                }
            })
            .then(function (data) {
                if (data.blob) {
                    fileDownload(data.blob, name_file);
                    openNotificationWithIcon('success', 'Downloaded', 'PDF file');
                } else {
                    openNotificationWithIcon('error', `There's no documents approved`, '');
                }
            });
    }

    async sendToSalesRepresentative(id: number, payload: any) {
        return await post(`/staff/sales-orders/${id}/send-to-sales-representative/`, payload);
    }

    async downloadTraveler(order_item_id: number, payload = {}) {
        const order_item: OrderLineItemProps | undefined = this.order?.line_items.find(
            item => item.id === order_item_id
        );
        await post(`/staff/sales-order-line-items/${order_item_id}/download-traveler/`, payload)
            .then(response => {
                const blob = dataURItoBlob(response);
                fileDownload(blob, `TRAVELER_${this.order?.name}_${order_item?.name}.xlsx`);
            })
            .catch(error => {
                console.log(error);
            });
    }

    async saveTraveler(order_item_id: number, payload = {}) {
        const order_item: OrderLineItemProps | undefined = this.order?.line_items.find(
            item => item.id === order_item_id
        );

        await post(`/staff/sales-order-line-items/${order_item_id}/save-traveler/`, payload)
            .then(response => {
                const blob = dataURItoBlob(response);
                fileDownload(blob, `TRAVELER_${this.order?.name}_${order_item?.name}.xlsx`);
            })
            .catch(error => {
                console.log(error);
            });
    }

    async createRework(order_id: number | string, payload: ICreateSalesOrderRework) {
        return await post(`/staff/sales-orders/${order_id}/create-rework/`, payload);
    }

    // This function is used via WebSocket to update the "name" field of an order.
    async changeOrderName(newName: string) {
        if (this.order) {
            this.order.name = newName;
        }
    }

    /**
     * Polls the backend to monitor the status of the "download all" task.
     *
     * @param {number} orderId - The ID of the sales order.
     * @param {string} taskId - The ID of the Celery task to monitor.
     * @param {number} interval - Polling interval in milliseconds (default: 2000ms).
     * @param {number} timeout - Maximum time to wait for task completion in milliseconds (default: 60000ms).
     * @returns {Promise<{ fileUrl: string, fileName: string }>} - Resolves with the file URL and file name on SUCCESS, rejects on FAILURE or timeout.
     */
        async pollTaskStatusForDownloadAll(orderId, taskId, interval = 5000, timeout = 300000) {
            const taskStatusEndpoint = `/staff/sales-orders/${orderId}/get-task-status-download-all/?task_id=${taskId}`;
            const startTime = Date.now();

            const checkTaskStatus = async () => {
                if (Date.now() - startTime > timeout) {
                    throw new Error("Polling timed out while waiting for task completion.");
                }

                const response = await get(taskStatusEndpoint);

                if (response.status === "SUCCESS") {
                    // Task completed successfully, return file details
                    return {
                        file_url: response.file_url,
                        file_name: response.file_name,
                    };
                } else if (response.status === "FAILURE") {
                    // Task failed
                    throw new Error("Task failed: " + (response.error || "Unknown error"));
                } else {
                    // Task is still processing, wait and retry
                    await new Promise(resolve => setTimeout(resolve, interval));
                    return checkTaskStatus(); // Recursive call
                }
            };

            return checkTaskStatus();
        }

    async downloadAllFiles(order_id: number) {
        try {
            // Step 1: Start the backend task to generate the ZIP file
            const { task_id: taskId } = await get(`/staff/sales-orders/${order_id}/download-all/`, {});

            if (!taskId) {
                throw new Error("Failed to initiate the task. Task ID is missing.");
            }

            // Step 2: Poll the backend for task completion
            const { file_url: fileUrl, file_name: fileName } = await this.pollTaskStatusForDownloadAll(order_id, taskId);

            // Step 3: Download the file using the provided URL and name
            const response = await axios.get(fileUrl, { responseType: "blob" }); // Get the file as a blob
            fileDownload(response.data, fileName);
        } catch (error: any) {
            console.error("Error during the file download process:", error.message);
        }
    }
}

export const SalesOrderStore = new SalesOrder();
