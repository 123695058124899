import React, { useEffect } from 'react';
import { ISalesOrderLineItemResponse } from '../../stores/SalesOrderLineItemStore';
import { BadgerTable } from '../badger-ui';
import { ColumnProps } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { IProductShipment, ITicket, ProductionProductStore } from '../../stores/ProductionProductStore';
import moment from 'moment/moment';
import { Divider, Typography } from 'antd';

interface Props {
    entity: ISalesOrderLineItemResponse;
}

const ProductionTicketsByProduct = (props: Props) => {
    const { entity } = props;

    const { allProductTicketsResponse } = ProductionProductStore;

    const tickets = allProductTicketsResponse.results;
    const totalRecords = allProductTicketsResponse.count;

    useEffect(() => {
        if (entity.product_zid) {
            ProductionProductStore.getTicketsProductId(entity.product_zid);
        }
    }, [entity.product_zid]);

    const columns: ColumnProps<ITicket>[] = [
        {
            title: 'Ticket Number',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Type',
            dataIndex: 'message_type',
            key: 'message_type',
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: value => new Date(value).toLocaleDateString(),
        },
        {
            title: 'Issues',
            dataIndex: 'issues',
            key: 'issues',
            render: issues => {
                return issues.map(issue => issue.description).join(', ');
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => {
                if (status == 'OPEN') {
                    return <Typography.Text type="danger">{status}</Typography.Text>;
                }
                if (status == 'CLOSED') {
                    return <Typography.Text type="success">{status}</Typography.Text>;
                }

                return <Typography.Text type={'warning'}>{status}</Typography.Text>;
            },
        },
        // {
        //     title: 'Updated',
        //     dataIndex: 'updated',
        //     key: 'updated',
        // },
    ];

    console.log('tickets', tickets);
    return (
        <BadgerTable<ITicket>
            dataSource={tickets}
            columns={columns}
            pagination={{
                totalRecords: totalRecords,
                pageSize: 30,
            }}
        />
    );
};

export default observer(ProductionTicketsByProduct);
