import React, { useEffect, useState } from 'react';
import { BadgerPage } from '../../components/badger-ui';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Descriptions, Progress, Row, Skeleton, Statistic, Tag, Typography, List, Badge } from 'antd';
import { SalesOrderLineItemStore } from '../../stores/SalesOrderLineItemStore';
import { InfoCircleOutlined, BankOutlined, RocketOutlined, ToolOutlined } from '@ant-design/icons';
import SalesOrderLinItemDetails from '../../components/SalesOrderLineItem/SalesOrderLinItemDetails';
import { observer } from 'mobx-react-lite';
import SalesOrderItemVendorShipments from '../../components/SalesOrderLineItem/ProductionShipmentsByProduct';
import { ProductionProductStore } from '../../stores/ProductionProductStore';
import ProductionProductBasicInformation from '../../components/SalesOrderLineItem/ProductionProductBasicInformation';
import ProductionBids from '../../components/SalesOrderLineItem/ProductionBidsByProduct';
import ProductDocuments from '../../components/SalesOrderLineItem/ProductionDocumentsByProduct';
import RequiredDocuments from '../../components/SalesOrderLineItem/RequiredDocuments';
import ProductionTicketsByProduct from '../../components/SalesOrderLineItem/ProductionTicketsByProduct';
import Shipments from '../../components/SalesOrderLineItem/Shipments';
import SalesOrderItemStatus from '../../components/SalesOrderLineItem/SalesOrderItemStatus';
import { hasGroup } from '../../components/User';
import SalesOrderItemInvoices from '../../components/SalesOrderLineItem/SalesOrderItemInvoices';

const { Text } = Typography;

type Params = {
    sales_order_line_item_id: string;
};

const SalesOrderLineItemDetailPage = () => {
    const history = useHistory();
    const { sales_order_line_item_id } = useParams<Params>();
    const [selectedSection, setSelectedSection] = useState('basicInfo');

    const { loading, entity: lineItem } = SalesOrderLineItemStore;

    const { entity: productionProduct } = ProductionProductStore;

    useEffect(() => {
        SalesOrderLineItemStore.getById(sales_order_line_item_id);
    }, [sales_order_line_item_id]);

    useEffect(() => {
        SalesOrderLineItemStore.getShipmentsByLineItemId(sales_order_line_item_id);
        SalesOrderLineItemStore.getInvoicesByLineItemId(sales_order_line_item_id);
    }, [sales_order_line_item_id]);

    useEffect(() => {
        if (lineItem?.product_zid) {
            ProductionProductStore.getById(lineItem.product_zid);
        }
    }, [lineItem?.product_zid]);

    const renderSectionContent = () => {
        if (!lineItem) {
            return <Skeleton active />;
        }

        switch (selectedSection) {
            case 'basicInfo':
                return (
                    <div id={'basicInfo'}>
                        <div
                            // title={
                            //     <>
                            //         <span> Basic Info</span>
                            //     </>
                            // }
                            style={{
                                width: '100%',
                            }}
                        >
                            <SalesOrderLinItemDetails lineItem={lineItem} />
                        </div>
                    </div>
                );

            case 'customer-shipments':
                return <Shipments sales_order_line_item_id={lineItem.id} />;
            case 'customer-invoices':
                return <SalesOrderItemInvoices sales_order_line_item_id={lineItem.id} />;
            case 'productionInfo':
                if (!productionProduct) {
                    return <Skeleton active />;
                }

                return (
                    <>
                        <div id={'productionInfo'}>
                            <Card
                                title={
                                    <>
                                        {productionProduct.previously_exists ? (
                                            <Tag color="blue">Repeat Work</Tag>
                                        ) : null}
                                        {/*// Contains a Repeat Work from {ordername}*/}
                                        {productionProduct.domestic_quoting ? <Tag color="green">Domestic</Tag> : null}
                                        {productionProduct.has_priority ? <Tag color="red">Priority</Tag> : null}
                                        {productionProduct.is_hot ? <Tag color="red">HOT</Tag> : null}

                                        {productionProduct.is_on_hold && <Tag color="error">On Hold</Tag>}

                                        {productionProduct.first_article_approval && (
                                            <Badge
                                                status="success"
                                                text="First Article Approval"
                                                style={{ marginRight: '8px' }}
                                            />
                                        )}
                                    </>
                                }
                                style={{
                                    width: '100%',
                                }}
                                extra={[
                                    <Progress
                                        key={'overallProgress'}
                                        type="circle"
                                        percent={productionProduct.overall_progress}
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        size={'small'}
                                        width={50}
                                        format={percent => `${percent}%`}
                                    />,
                                ]}
                            >
                                {productionProduct ? (
                                    <ProductionProductBasicInformation product={productionProduct} />
                                ) : null}
                            </Card>
                        </div>

                        {/*<Card title={'Status Progress'} style={{ width: '100%' }}>*/}
                        {/*    <div style={{ marginBottom: '16px' }}>*/}
                        {/*        {productionProduct.statuses.map(status => (*/}
                        {/*            <div key={status.name} style={{ marginBottom: '16px' }}>*/}
                        {/*                <h4>{status.name}</h4>*/}
                        {/*                <Progress*/}
                        {/*                    percent={status.progress}*/}
                        {/*                    status="active"*/}
                        {/*                    strokeColor={{*/}
                        {/*                        '0%': '#108ee9',*/}
                        {/*                        '100%': '#87d068',*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*    <br />*/}
                        {/*</Card>*/}

                        <br />

                        <div id={'productBids'}>
                            <Card
                                title={'Bids'}
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        {productionProduct.statuses
                                            .filter(r => r.name == 'Bidding')
                                            .map(status => (
                                                <div key={status.name} style={{ marginBottom: '16px' }}>
                                                    <Progress
                                                        percent={status.progress}
                                                        status="active"
                                                        strokeColor={{
                                                            '0%': '#108ee9',
                                                            '100%': '#87d068',
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <Card>
                                            <Statistic
                                                title="Awarded Vendor"
                                                value={lineItem.awarded_vendor || 'N/A'}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card>
                                            <Statistic
                                                title="Award Date"
                                                value={
                                                    lineItem.award_date
                                                        ? new Date(lineItem.award_date).toLocaleDateString()
                                                        : 'N/A'
                                                }
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card>
                                            <Statistic
                                                title="Vendor Price"
                                                value={lineItem.awarded_vendor_price || 'N/A'}
                                                prefix="$"
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                                <br />

                                <ProductionBids entity={lineItem} />
                            </Card>
                        </div>

                        <br />

                        <div id={'productionShipments'}>
                            <Card
                                title={'Shipments'}
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        {productionProduct.statuses
                                            .filter(r => r.name == 'Shipping')
                                            .map(status => (
                                                <div key={status.name} style={{ marginBottom: '16px' }}>
                                                    <Progress
                                                        percent={status.progress}
                                                        status="active"
                                                        strokeColor={{
                                                            '0%': '#108ee9',
                                                            '100%': '#87d068',
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Card>
                                            <Statistic
                                                title="Quantity Shipped"
                                                value={productionProduct?.quantity_shipped}
                                                valueStyle={{
                                                    color: productionProduct?.quantity_shipped > 0 ? 'green' : 'red',
                                                }}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card>
                                            <Statistic
                                                title="Quantity Remaining to Ship"
                                                value={productionProduct?.quantity_remaining_to_ship}
                                                valueStyle={{
                                                    color:
                                                        productionProduct?.quantity_remaining_to_ship > 0
                                                            ? 'red'
                                                            : 'green',
                                                }}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                                <br />
                                <SalesOrderItemVendorShipments entity={lineItem} />
                            </Card>
                        </div>
                        <br />
                        <div id="productDocuments">
                            <Card
                                title={'Documentation'}
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        {productionProduct.statuses
                                            .filter(r => r.name == 'Documentation')
                                            .map(status => (
                                                <div key={status.name} style={{ marginBottom: '16px' }}>
                                                    <Progress
                                                        percent={status.progress}
                                                        status="active"
                                                        strokeColor={{
                                                            '0%': '#108ee9',
                                                            '100%': '#87d068',
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                    </Col>
                                </Row>

                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <RequiredDocuments
                                            requirements={productionProduct.vendor_requirements_config}
                                            isProductionTeam={false}
                                        />
                                    </Col>

                                    <Col span={12}>
                                        <RequiredDocuments
                                            requirements={productionProduct.production_team_requirements_config}
                                            isProductionTeam={true}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <ProductDocuments entity={lineItem} />
                                    </Col>
                                </Row>
                            </Card>
                        </div>

                        <br />
                        <div id="productTickets">
                            <Card
                                title={'Product Tickets'}
                                style={{
                                    width: '100%',
                                }}
                            >
                                <ProductionTicketsByProduct entity={lineItem} />
                            </Card>
                        </div>
                    </>
                );

            default:
                return null;
        }
    };

    const getMenuLinks = lineItem => {
        if (!lineItem) {
            return [];
        }

        const menuLinks = [
            {
                title: 'Basic Information',
                link: 'basicInfo',
                icon: <InfoCircleOutlined />,
            },
            {
                title: 'Shipments',
                link: 'customer-shipments',
                icon: <RocketOutlined />,
                counter: SalesOrderLineItemStore.shipments.length,
            },
            {
                title: 'Invoices',
                link: 'customer-invoices',
                icon: <BankOutlined />,
                counter: SalesOrderLineItemStore.invoices.length,
            },
        ];

        if (hasGroup && hasGroup('Production')) {
            menuLinks.push({
                title: 'Production',
                link: 'productionInfo',
                icon: <ToolOutlined />,
            });
        }

        return menuLinks;
    };

    return (
        <BadgerPage
            historyChanges={{
                model_name: 'sales-order-line-items',
                pk: lineItem?.id ?? 0,
            }}
            isLoading={loading}
            title={`${lineItem?.sales_order?.name} - ${lineItem?.name ?? 'loading..'}`}
            subtitle={<>{lineItem ? <SalesOrderItemStatus status={lineItem.status} /> : null}</>}
            handleBack={() => history.goBack()}
            sidebarLinks={getMenuLinks(lineItem)}
            handleSidebarClick={key => setSelectedSection(key)}
        >
            {renderSectionContent()}
        </BadgerPage>
    );
};

export default observer(SalesOrderLineItemDetailPage);
