import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, Input, Menu, Modal, notification } from 'antd';
import {
    DownOutlined,
    EditOutlined,
    EyeOutlined,
    FilePdfOutlined,
    MinusOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { QuoteStore } from '../../stores';
import { SalesOrderStore } from '@partsbadger/sales_order_tool';
import { get, post } from '../../shared';
import FileDownload from 'js-file-download';
import { QuotePreview } from '@partsbadger/quote-tool';
import { FormEditSubmission } from '../../Forms/RFQ';

interface QuoteActionsProps {
    quote: {
        id: number;
        name: string;
        completed: boolean;
        deal: number;
    };
    rfq: {
        id: number;
        account: {
            id: number;
            name: string;
        } | null;
        contact?: {
            id: number;
            name: string;
        } | null;
        owner: {
            id: number;
            name: string;
        };
    } | null;
    handleRefresh: () => void;
}

const QuoteMenuActions = (props: QuoteActionsProps) => {
    const { quote, rfq, handleRefresh } = props;
    const history = useHistory();
    const [previewQuote, setPreviewQuote] = useState<number>(0);
    const [no_quoted_notes, setNo_quoted_notes] = useState('');

    const [downloading, setDownloading] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const [recordEdit, setEditRecord] = useState<{
        id: number;
        account: {
            id: number;
            name: string;
        } | null;
        contact?: {
            id: number;
            name: string;
        } | null;
        owner: {
            id: number;
            name: string;
        };
    } | null>(null);
    const [editSubmission, setEditSubmission] = useState(false);
    const [noQuoted, setNoQuoted] = useState<number>(0);

    useEffect(() => {
        document.addEventListener('keydown', event => {
            if (event.code === 'Escape') setNoQuoted(0);
        });
    }, []);

    const downloadQuote = () => {
        const url = `staff/quotes/${quote.id}/export/`;

        setDownloading(true);
        get(url, {}, { responseType: 'blob' })
            .then((response: any) => {
                FileDownload(response.data, `${quote.name}.pdf`);
                setDownloading(false);
            })
            .catch(() => {
                setDownloading(false);
            });
    };

    const menu = (
        <Menu>
            <Menu.Item
                onClick={() => {
                    if (quote) {
                        setPreviewQuote(quote.id);
                    }
                }}
                className="flex items-center"
            >
                <FilePdfOutlined />
                <span>Preview Quote</span>
            </Menu.Item>

            <Menu.Item
                onClick={() => {
                    downloadQuote();
                }}
                className="flex items-center"
            >
                <FilePdfOutlined />
                <span>Download Quote PDF</span>
            </Menu.Item>

            <Menu.Item>
                <Link className="flex items-center" to={'/quotes/' + quote?.id + '/'}>
                    <EyeOutlined className="pr-1" />
                    <span>Open with Quote Tool</span>
                </Link>
            </Menu.Item>

            <Menu.Item>
                <a
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    target={'_blank'}
                    rel="noreferrer"
                    href={`https://api.parts-badger.com/redirect-zoho/Deal/${quote.deal}`}
                    className="flex items-center"
                >
                    <EyeOutlined className="pr-1" /> <span>View Deal</span>
                </a>
            </Menu.Item>

            {!quote.completed && rfq && (
                <Menu.Item
                    onClick={() => {
                        if (rfq.account !== null && rfq.contact != null) {
                            setEditRecord({
                                id: rfq.id,
                                account: {
                                    id: rfq.account.id,
                                    name: rfq.account.name,
                                },
                                contact: {
                                    id: rfq.contact.id,
                                    name: rfq.contact.name,
                                },
                                owner: {
                                    id: rfq.owner.id,
                                    name: rfq.owner.name,
                                },
                            });
                            setEditSubmission(true);
                        } else {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'This RFQ does not have a registered account/contact.',
                                description: 'Make sure that the RFQ has an associated account and contact.',
                                placement: 'top',
                                maxCount: 1,
                            });
                        }
                    }}
                    className="flex items-center"
                >
                    <EditOutlined /> <span>Edit Submission</span>
                </Menu.Item>
            )}

            {quote.completed && (
                <Menu.Item
                    className="flex items-center"
                    onClick={() => {
                        if (quote) {
                            setDuplicating(true);
                            QuoteStore.getRequote(quote.id)
                                .then((r: any) => {
                                    setDuplicating(false);
                                    history.push('/get-quote/?uid=' + r.data.uid);
                                })
                                .catch(() => setDuplicating(false));
                        }
                    }}
                >
                    <PlusOutlined /> <span>Re Quote</span>
                </Menu.Item>
            )}

            {!quote?.completed && rfq && (
                <Menu.Item onClick={() => setNoQuoted(rfq.id)} className="flex items-center">
                    <MinusOutlined />
                    <span>Mark as no quoted</span>
                </Menu.Item>
            )}

            <Menu.Item>
                <a
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    target={'_blank'}
                    rel="noreferrer"
                    href={`https://portal.parts-badger.com/my-account/quotes/` + quote.id + `/modifications`}
                    className="flex items-center"
                >
                    <EyeOutlined className="pr-1" /> <span>Dynamic quoting</span>
                </a>
            </Menu.Item>

            {quote?.completed && rfq && rfq.contact?.id && (
                <Menu.Item
                    className="flex items-center"
                    onClick={async e => {
                        if (rfq.contact) {
                            await SalesOrderStore.getOrCreateSalesOrder(rfq.contact.id).then(async () => {
                                await SalesOrderStore.update(SalesOrderStore.order_id, {
                                    quotes: [],
                                });
                                if (rfq.contact?.id) {
                                    await SalesOrderStore.update(SalesOrderStore.order_id, {
                                        contact: rfq.contact?.id,
                                        quotes: [quote?.id],
                                    });
                                }

                                history.push('/sales-order/new/');
                            });
                            SalesOrderStore.getContactById(rfq.contact.id);
                            history.push('/sales-order/new/');
                        }
                    }}
                >
                    <PlusOutlined /> <span>Submit for an Order</span>
                </Menu.Item>
            )}
        </Menu>
    );
    return (
        <>
            {previewQuote > 0 && (
                <QuotePreview
                    quote_id={previewQuote}
                    handleClose={() => {
                        setPreviewQuote(0);
                    }}
                />
            )}

            <Modal
                open={noQuoted > 0}
                onCancel={() => setNoQuoted(0)}
                onOk={() => {
                    // Prefill
                    post('staff/rfq/' + noQuoted + '/unquoted/', {
                        unquoted_notes: no_quoted_notes,
                    }).then(() => {
                        handleRefresh();
                    });
                    setNoQuoted(0);
                }}
            >
                <Input.TextArea
                    placeholder={'Reason, and press enter or just press enter'}
                    value={no_quoted_notes}
                    onChange={event => setNo_quoted_notes(event.target.value)}
                />
            </Modal>

            <Modal
                open={editSubmission}
                title={'Edit account, contact and sales representative'}
                onCancel={() => setEditSubmission(false)}
                footer={false}
            >
                {editSubmission && recordEdit && (
                    <FormEditSubmission
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        rfq_id={recordEdit.id}
                        account={
                            recordEdit?.account
                                ? {
                                      id: recordEdit.account.id,
                                      name: recordEdit.account.name,
                                  }
                                : null
                        }
                        contact={
                            recordEdit?.contact
                                ? {
                                      id: recordEdit.contact.id,
                                      name: recordEdit.contact.name,
                                  }
                                : null
                        }
                        owner={{
                            id: recordEdit.owner.id,
                            name: recordEdit.owner.name,
                        }}
                        setEditSubmission={(value: any) => {
                            handleRefresh();
                            setEditSubmission(value);
                        }}
                    />
                )}
            </Modal>

            <Dropdown overlay={menu}>
                <span className={'link'}>
                    Actions <DownOutlined />
                </span>
            </Dropdown>
        </>
    );
};

export default observer(QuoteMenuActions);
