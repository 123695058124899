import { action, makeObservable, observable, runInAction } from 'mobx';
import { get } from '@partsbadger/utils';
import { IShipmentProps } from '../../utils/types';

class SalesOrderLineItem {
    loading = false;
    entity: ISalesOrderLineItemResponse | null = null;
    shipments: IShipmentProps[] = [];
    invoices: ISalesOrderLineItem[] = [];
    constructor() {
        makeObservable(this, {
            // Observables

            loading: observable,
            entity: observable,
            shipments: observable,
            invoices: observable,

            // Actions
            getById: action,
            getShipmentsByLineItemId: action,
            getInvoicesByLineItemId: action,
        });
    }

    async getById(id: number | string) {
        runInAction(() => {
            this.loading = true;
        });

        const data = await get(`/staff/sales-order-line-items/${id}/`);

        runInAction(() => {
            this.entity = data;
            this.loading = false;
        });

        return data;
    }

    async getShipmentsByLineItemId(id: number | string) {
        runInAction(() => {
            this.loading = true;
        });

        const data = await get(`/staff/sales-order-line-items/${id}/shipments/`);

        runInAction(() => {
            this.shipments = data.results;
            this.loading = false;
        });

        return data;
    }

    async getInvoicesByLineItemId(id: number | string) {
        runInAction(() => {
            this.loading = true;
        });

        const data = await get(`/staff/sales-order-line-items/${id}/invoices/`);

        runInAction(() => {
            this.invoices = data.results;
            this.loading = false;
        });

        return data;
    }
}

export interface ISalesOrderLineItemResponse {
    id: number;
    status: string;
    redacted_2d_file: string;
    redacted_3d_file: string;
    quote_has_product: QuoteHasProduct;
    production_3d_file?: Production3DFileClass;
    production_2d_file?: Production2DFileClass;
    production_other_files: ProductionOtherFile[];
    suggested_lead_time: number;
    quantity_in_z_outtakes: number;
    stock: number;
    notes: any[];
    sample_original: null;
    invoiced_quantity: number;
    quantity_shipped: number;
    quote_reference_id: null;
    uid: string;
    created_time: Date;
    modified_time: Date;
    zoho_id: string;
    active: boolean;
    deleted: boolean;
    permission_policy: string;
    name: string;
    product_description: null;
    quantity: number;
    discount: string;
    unit_price: string;
    list_price: string;
    taxes: string;
    total: string;
    total_after_discount: string;
    quantity_remaining_to_receive: number;
    quantity_remaining_to_ship: number;
    intake_status: string;
    shipping_status: string;
    date_shipped_in_full: Date;
    date_received_in_full: Date;
    awarded_vendor: string;
    award_date: Date;
    awarded_vendor_total_price: string;
    awarded_vendor_price: string;
    product_zid: string;
    required_part_ship_date: Date;
    required_partner_ship_date: Date;
    revised_partner_ship_date: Date;
    vendor_requirements: string[];
    vendor_requirement_notes: null;
    in_house_requirements: null;
    in_house_requirement_notes: null;
    vendor_qualifications: string[];
    hardware_required: boolean;
    domestic_process_needed: boolean;
    domestic_processes: any[];
    hardwares_required: any[];
    first_article_approval: boolean;
    is_batch_shipment: boolean;
    quantity_sample_required: null;
    batch_shipments: BatchShipment[];
    sample_approval_status: null;
    sample_approved_date: null;
    sample_customer_statement: null;
    product_feedback: null;
    production_3d_file_approval_status: null;
    production_3d_file_approval_feedback: null;
    partsbadger_production: boolean;
    partsbadger_production_method: null;
    partsbadger_production_quoting_notes: null;
    production_team_requirements: string[];
    domestic_quoting: boolean;
    production_status: null;
    original_quantity: number;
    credit_memo_needed: boolean;
    owner: number;
    created_by: number;
    modified_by: number;
    deleted_by: null;
    site: number;
    sales_order: any;
    master_product: number;
    duplicated_from: null;
    users: any[];
    groups: any[];
    sample_documents: any[];
}

export interface BatchShipment {
    quantity: number;
    order_item: number;
    required_part_ship_date: string;
}

export interface Production2DFileClass {
    id: number;
    filename: string;
    file: string;
    convertable_file: null;
    redacted_file: null | string;
    redacted_filename: null | string;
    part_name: null;
    suggestions: Suggestion[];
    thumbnail: string;
    thumbnail_redacted: null | string;
    redaction_status: string;
    redact_as_flat_file: boolean;
    suggested_materials_text: string;
    suggested_coatings_text: string;
    suggested_finishes_text: string;
}

export interface Suggestion {
    suggested_material: Material | null;
    suggested_finish: null;
    suggested_coating: SuggestedCoating | null;
    drawing_file: number;
    cropped_image: CroppedImage;
    version: string;
}

export interface CroppedImage {
    id: number;
    file: string;
}

export interface SuggestedCoating {
    id: number;
    name: string;
    standard: boolean;
}

export interface Material {
    id: number;
    name: string;
    manufacturing_types: string[];
    standard: boolean;
    uns: string;
}

export interface Production3DFileClass {
    id: number;
    part_name: string;
    filename: string;
    convertable_file: null;
    redacted_file: null;
    image: string;
    analyzed_status: string;
    file_3djs: string;
    analysis_status_v2: string;
    step_file_analysis_data: null;
}

export interface ProductionOtherFile {
    id: number;
    filename: string;
    file: string;
    unacceptable_keywords: null;
}

export interface QuoteHasProduct {
    id: number;
    quote: number;
    product: Product;
    expiration_date: Date;
    quantity: number;
    quantities: any[];
    lead_time: number;
    work_price: string;
    manual_adjustment: string;
    formula: string;
    unit_price: string;
    fixed_unit_price: boolean;
    vendor_price: string;
    vendor_price_adjust: string;
    setup_price: string;
    material_price: string;
    shipping_cost: string;
    shipping_customer: string;
    total_price: string;
    is_expired: boolean;
    name: string;
    discount: string;
    quantity_range: null;
    lead_time_range: null;
    manufacturing_type: string;
    reinforcement: null;
    reinforcement_percent: null;
    safe_auto_quote: boolean;
    has_material_cert: boolean;
    position: number;
    debug: Debug;
    material_group_lt_adjustment: number;
    is_fai_required: boolean;
    quote_item_quantities: any[];
    quantity_max: number;
    quantity_min: number;
    unit_price_adjust_range_min: string;
    unit_price_adjust_range_max: string;
    unit_price_adjust_type: string;
    lead_time_adjust_range_min: null;
    lead_time_adjust_range_max: null;
    has_dynamic_quantities: boolean;
    batch_shipment: boolean;
    setup: string;
    program: string;
    rt: string;
    setup_hours: string;
    programming_hours: string;
    runtime: string;
    total_material: string;
    coating1: string;
    coating2: string;
    extra1: string;
    extra2: string;
    master_product: MasterProduct;
    fast_lane: string;
    partsbadger_production: boolean;
    partsbadger_production_method: null;
}

export interface Debug {
    badger: number;
    weight: number;
    version: string;
    quantity: number;
    lead_time: number;
    unit_price: number;
    work_price: number;
    finish_name: string;
    part_volume: number;
    removed_vol: number;
    setup_price: number;
    total_price: number;
    coating_name: string;
    total_badger: number;
    total_volume: number;
    badger_factor: number;
    material_cost: number;
    material_name: string;
    face_directions: number;
    estimated_badger: number;
    finish_unit_cost: null;
    material_density: number;
    coating_unit_cost: null;
    finish_setup_cost: null;
    safe_to_autoquote: boolean;
    total_finish_unit: null;
    total_part_weight: number;
    coating_setup_cost: number;
    tighter_tolerances: boolean;
    total_coating_unit: null;
    total_finish_setup: number;
    total_badger_factor: number;
    total_coating_setup: number;
    total_machine_price: number;
    total_material_price: number;
    total_removed_weight: number;
    estimated_cre_machine: number;
    estimated_part_weight: number;
    material_machine_time: number;
    total_quantity_factor: number;
    vendor_rfq_unit_price: number;
    estimated_cre_material: number;
    internal_shipping_cost: number;
    total_combined_coatings: number;
    total_combined_finishes: number;
    estimated_removed_weight: number;
    total_machine_time_factor: number;
    estimated_combined_coatings: number;
    estimated_combined_finishes: number;
    estimated_cre_badger_factor: number;
    estimated_vendor_total_price: number;
    estimated_cre_quantity_factor: number;
    estimated_cre_machine_time_factor: number;
}

export interface MasterProduct {
    id: number;
    internal_files: any[];
}

export interface Product {
    id: number;
    name: string;
    master_product: number;
    taps: number;
    sides: number;
    material: Material;
    color: null;
    part_tolerance: null;
    hole_tolerance: null;
    coatings: Coating[];
    finishes: Coating[];
    finish: Coating;
    coating: Coating;
    design_time: string;
    dimensions: string;
    has_file_3d: boolean;
    has_file_2d: boolean;
    required_ship_date: null;
    invoice_on_shipped_in_full: null;
    required_partner_ship_date: Date;
    revised_partner_ship_date: null;
    vendor_requirements: string[];
    vendor_requirement_notes: null;
    in_house_requirements: null;
    in_house_requirement_notes: null;
    drawing_file: Production2DFileClass;
    step_file: Production3DFileClass;
}

export interface Coating {
    id: number;
    name: string;
    unit_cost?: number;
    setup_cost: number;
    volume_cost: number;
    needs_manual_adjust: boolean;
    metric_name?: string;
}

//
export interface ISalesOrderLineItem {
    id: number;
    name: string;
    invoice_id: number;
    invoice_number: string;
    invoice_date: string;
    quantity: number;
    discount: string;
    list_price: string;
    total: string;
    status: string;
    created_time: string;
    sales_order: {
        id: number;
        name: string;
    };
}
export const SalesOrderLineItemStore = new SalesOrderLineItem();
