import * as React from 'react';
import { ALLOWED_FILES } from './FileExtensions';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Upload } from 'antd';
import { BASE_URL } from '../../../constants/app';
import { getToken } from '../../User';

enum Types {
    'default' = 'default',
    'file2d' = '2D',
    'file3d' = '3D',
}

export const UploadFile = (props: any) => {
    const { multiple, onChange, onUpload, onRemove, type, value } = props;

    const token = getToken();

    const handleChange = (info: any) => {
        let fileList = info.fileList;

        if (!multiple) {
            fileList = fileList.slice(-1);
        }

        if (onChange) {
            onChange(fileList);
        }

        const { status } = info.file;

        if (onUpload && status === 'done') {
            onUpload(info.file);
        }

        if (onRemove && status === 'remove') {
            onRemove(info.file);
        }
    };

    let url = `customer/files/`;
    let allowed_files = '';

    if (!props?.accept) {
        switch (type) {
            case Types.file3d:
                url = `customer/step-files/`;
                allowed_files = ALLOWED_FILES.allowed_3d.map(r => `.${r.toLowerCase()}`).toString();
                break;

            case Types.file2d:
                url = `customer/drawing-files/`;
                allowed_files = ALLOWED_FILES.allowed_2d.map(r => `.${r.toLowerCase()}`).toString();
                break;
        }
    }

    let otherProps = {};

    if (value) {
        otherProps = {
            fileList: value,
        };
    }

    return (
        <Upload.Dragger
            name="file"
            action={BASE_URL + '/' + url}
            headers={{
                Authorization: `Token ${token}`,
            }}
            accept={allowed_files}
            {...otherProps}
            {...props}
            onChange={handleChange}
        >
            <LegacyIcon type={'upload'} />
        </Upload.Dragger>
    );
};

/**
 * Map the response of the api to a local format to be used in UploadFile component
 * @param file
 * @returns {{uid: *, response: {id: *, url: *}, name: *, status: string}}
 */
export const normalize_files = (file: any) => {
    if (!file) {
        return;
    }
    return {
        uid: file.id,
        name: file.filename,
        status: 'done',
        response: {
            id: file.id,
            url: file.file,
        },
    };
};
