import React, { useEffect } from 'react';
import { ISalesOrderLineItemResponse } from '../../stores/SalesOrderLineItemStore';
import { BadgerTable } from '../badger-ui';
import { ColumnProps } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { IProductShipment, ProductionProductStore } from '../../stores/ProductionProductStore';
import moment from 'moment/moment';
import { Divider } from 'antd';

interface Props {
    entity: ISalesOrderLineItemResponse;
}

const ProductionShipmentsByProduct = (props: Props) => {
    const { entity } = props;

    const { allProductShipmentsResponse } = ProductionProductStore;

    const shipments = allProductShipmentsResponse.results;
    const totalRecords = allProductShipmentsResponse.count;

    useEffect(() => {
        if (entity.product_zid) {
            ProductionProductStore.getShipments(entity.product_zid);
        }
    }, [entity.product_zid]);

    const columns: ColumnProps<IProductShipment>[] = [
        {
            title: 'Shipment Number',
            dataIndex: 'outtake',
            key: 'outtake',
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
        },
        {
            title: 'Created Time',
            dataIndex: 'created_time',
            render: value => new Date(value).toLocaleDateString(),
        },
        {
            title: 'Quantity Shipped',
            dataIndex: 'quantity_shipped',
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
        },
        {
            title: 'Number of Boxes',
            dataIndex: 'number_of_boxes',
        },
        {
            title: 'Tracking Number',
            dataIndex: 'tracking_code',
            render: tracking_code => {
                return (
                    <>
                        {tracking_code !== '' ? (
                            <>
                                <div className={'mt-2'}>
                                    <div>{tracking_code}</div>
                                    <div>
                                        <a
                                            title={'Try in DHL'}
                                            target={'_blank'}
                                            rel={'noreferrer'}
                                            href={`https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${tracking_code}`}
                                        >
                                            DHL
                                        </a>

                                        <Divider type={'vertical'} />

                                        <a
                                            title={'Try in FedEx'}
                                            style={{
                                                marginLeft: 5,
                                            }}
                                            target={'_blank'}
                                            rel={'noreferrer'}
                                            href={`https://www.fedex.com/fedextrack/?trknbr=${tracking_code}`}
                                        >
                                            FEDEX
                                        </a>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className={'mt-2'}></div>
                        )}
                    </>
                );
            },
        },
    ];
    return (
        <BadgerTable<IProductShipment>
            dataSource={shipments}
            columns={columns}
            pagination={{
                totalRecords: totalRecords,
                pageSize: 30,
            }}
        />
    );
};

export default observer(ProductionShipmentsByProduct);
