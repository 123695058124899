import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Empty, message } from 'antd';
import { ColumnProps } from 'antd/es/table';

import { IShipmentProps } from '../../../../utils/types';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { DownloadFile } from '@partsbadger/utils';
import { observer } from 'mobx-react';
import TrackingLink from '../../Buttons/TrackingLink';
import { BadgerTable } from '../../badger-ui';
import { SalesOrderLineItemStore } from '../../../stores/SalesOrderLineItemStore';

interface IProps {
    sales_order_line_item_id: number;
}

const Shipments: React.FC<IProps> = props => {
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (props.sales_order_line_item_id) {
            // SalesOrderLineItemStore.getShipmentsByLineItemId(props.sales_order_line_item_id);
        }
    }, [props.sales_order_line_item_id]);

    // const getPackList = async (id: number, box_id: number) => {
    //     setLoading(true);
    //     SalesOrderStore.getPackListBySalesOrderId(id, box_id)
    //         .then(data => {
    //             DownloadFile(data, `${props.order?.carrier ? props.order?.carrier + '-' + box_id : 'pack-' + id}.pdf`);
    //             setLoading(false);
    //             message.success('Document downloaded!');
    //         })
    //         .catch(error => message.error(`Error loading PackList: ${error}`))
    //         .finally(() => setLoading(false));
    // };

    const columns: ColumnProps<IShipmentProps>[] = [
        {
            title: 'Box ID',
            dataIndex: 'id',
            key: 'id',
        },

        {
            title: 'Ship Date',
            dataIndex: 'date',
            key: 'date',
            // width: '10%',
            render: (text, record) => {
                if (record.created_time != null) {
                    return <span>{dayjs(record.created_time).add(7, 'day').format('YYYY-MM-DD')}</span>;
                }
                return <span>--</span>;
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Tracking',
            dataIndex: 'tracking_number',
            key: 'tracking_number',
            // align: 'center',
            render: (text, record) => {
                if (record.tracking_number != null) {
                    if (record.carrier != null) {
                        return <TrackingLink carrier={record.carrier} trackingCode={record.tracking_number} />;
                    }
                }
                return <span>--</span>;
            },
        },
    ];

    return (
        <BadgerTable<IShipmentProps>
            rowKey={record => record.id.toString()}
            columns={columns}
            className="border"
            dataSource={SalesOrderLineItemStore.shipments}
            pagination={{
                totalRecords: SalesOrderLineItemStore.shipments.length,
            }}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span style={{ color: '#000' }}>This product does not have shipments.</span>}
                    />
                ),
            }}
        />
    );
};

export default observer(Shipments);
