import { action, makeObservable, observable, runInAction } from 'mobx';
import { get } from '@partsbadger/utils';

class ProductionProduct {
    loading = false;
    entity: IProductionProduct | null = null;
    allProductShipmentsResponse: IProductShipmentsResponse = {
        count: 0,
        next: null,
        previous: null,
        results: [],
    };

    allProductBidsResponse: IProductBidsResponse = {
        count: 0,
        next: null,
        previous: null,
        results: [],
    };

    allProductDocumentsResponse: IProductDocumentsResponse = {
        count: 0,
        next: null,
        previous: null,
        results: [],
    };

    allInvitedVendorsToBidResponse: any = {
        count: 0,
        next: null,
        previous: null,
        results: [],
    };

    allProductTicketsResponse: any = {
        count: 0,
        next: null,
        previous: null,
        results: [],
    };

    constructor() {
        makeObservable(this, {
            // Observables
            loading: observable,
            entity: observable,

            allProductShipmentsResponse: observable,
            allProductBidsResponse: observable,
            allProductDocumentsResponse: observable,
            allInvitedVendorsToBidResponse: observable,
            allProductTicketsResponse: observable,

            // Actions
            getById: action,
            getShipments: action,
            getBidsByProductId: action,
            getDocumentsByProductId: action,
            getInvitedVendorsToBidByProductId: action,
            getTicketsProductId: action,
        });
    }

    async getById(id: number | string) {
        runInAction(() => {
            this.loading = true;
        });

        const data = await get(`/vendor/v1/products/${id}/`);

        runInAction(() => {
            this.entity = data;
            this.loading = false;
        });

        return data;
    }

    async getShipments(id: number | string) {
        const data = await get(`/vendor/v1/products/${id}/shipments/`);

        this.allProductShipmentsResponse = data;

        return data;
    }

    async getBidsByProductId(id: number | string) {
        const data = await get(`/vendor/v1/products/${id}/bids/`);

        this.allProductBidsResponse = data;

        return data;
    }

    //     getDocumendtsByProductId
    async getDocumentsByProductId(product_id: string) {
        const data = await get(`/vendor/v1/products/${product_id}/documents/`);
        this.allProductDocumentsResponse = data;
    }

    async getInvitedVendorsToBidByProductId(product_id: string) {
        const data = await get(`/vendor/v1/products/${product_id}/invited-vendors/`);
        this.allInvitedVendorsToBidResponse = data;
    }

    async getTicketsProductId(product_id: string) {
        const data = await get(`/vendor/v1/products/${product_id}/tickets/`);
        this.allProductTicketsResponse = data;
    }
}
export interface IRequirementsConfig {
    requirement: string;
    config: string;
    required_upload: boolean;
    required_approval: boolean;
}

export interface IProductionProduct {
    product_id: string;
    product_name: string;
    statuses: Array<{ name: string; progress: number }>;
    required_partner_ship_date: string;
    revised_ship_date: string;
    overall_progress: number;
    quantity: number;
    material_type: string;
    material: string;
    surface_finish: string;
    coating: string;
    estimated_vendor_price: string;
    unit_price: string;
    total_price: string;
    vendor_ship_string: string;
    product_cancelled: boolean;
    revised_ship_string: string;
    reason_revised_ship_string: string;
    has_priority: boolean;
    is_hot: boolean;
    is_delayed: boolean;
    quantity_shipped: number;
    quantity_remaining_to_ship: number;
    required_partner_ship_string: string;
    vendor_requirement_status: IVendorRequirementStatus;
    vendor_requirements_config: IRequirementsConfig[];
    production_team_requirements_config: IRequirementsConfig[];
    vendor_requirements: Array<string>;
    vendor_requirement_notes: null;
    in_house_requirements: null;
    in_house_requirement_notes: null;
    created: string;
    delay_reason: number;
    previously_exists: boolean;
    estimated_shipping_cost: string;
    has_vendor_issue: boolean;
    file_2d_source: null;
    file2d: string;
    file_3d_source: null;
    file3d: string;
    file_js: string;
    file_png: string;
    first_article_approval: boolean;
    sample_approval_status: null;
    sample_approved_string: null;
    sample_original: null;
    product_feedback: null;
    is_on_hold: boolean;
    quote_reference: null;
    partsbadger_production_method: null;
    sample_assign_type: null;
    sample_customer_statement: null;
    custom_material: null;
    all_vendor_requirements_uploaded: boolean;
    all_production_team_requirements_uploaded: boolean;
    all_vendor_requirements_approved: boolean;
    all_production_team_requirements_approved: boolean;
    production_team_requirements: Array<string>;
    latest_shipment_string: string;
    status: null;
    original_quantity: number;
    cancelled_quantity: number;
    file2d_hash: string;
    file3d_hash: string;
    previous_quantity: number;
    domestic_quoting: boolean;
    vendor_rfq_quoter_name: string;
    vendor_rfq_quoted_price: string;
    quotation_source: string;
    is_batch_shipment: boolean;
}

export interface IVendorRequirementStatus {
    [key: string]: {
        approved: boolean;
        key: string;
    }[];
}

// Product Shipments

export interface IProductShipmentsResponse {
    count: number;
    next: null;
    previous: null;
    results: IProductShipment[];
}

export interface IProductShipment {
    outtake: number;
    tracking_code: string | null;
    quantity_shipped: number;
    weight: string;
    number_of_boxes: string;
    created_time: string;
    barcode_number: null;
    barcode: null;
}

// Bids

export interface IProductBidsResponse {
    count: number;
    next: null;
    previous: null;
    results: IProductBids[];
}

export interface IProductBids {
    bid: number;
    created: Date;
    vendor: string;
    unit_price: string;
    total_price: string;
    notes: string;
    status: boolean;
    // suggested_partner_ship_date: null;
}

// IProductDocumentsResponse

export interface IProductDocumentsResponse {
    count: number;
    next: null;
    previous: null;
    results: IProductDocument[];
}

export interface IProductDocument {
    key: string;
    document_type: string;
    certificate_number: null;
    approved: boolean;
}

// Invited Vendors

export interface IInvitedVendorsToBidResponse {
    count: number;
    next: null;
    previous: null;
    results: IInvitedVendorsToBid[];
}

export interface IInvitedVendorsToBid {
    vendor: string;
    created: string;
}

// Ticket

export interface IProductTicketResponse {
    count: number;
    next: null;
    previous: null;
    results: ITicket[];
}

export interface IIssue {
    description: string;
}
export interface ITicket {
    id: string;
    message_type: string;
    created: Date;
    product: null;
    products: string[];
    issues: IIssue[];
    vendor: number;
    status: string;
    updated: Date;
}

export const ProductionProductStore = new ProductionProduct();
