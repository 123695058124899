import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, notification } from 'antd';
import { patch } from '../../shared';
import { Accounts, Contacts, ErrorRender, GenericSelect } from '@partsbadger/library';
import React, { useState } from 'react';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Checkbox } from 'antd/es';

interface Props extends FormComponentProps {
    rfq_id: number;
    account: {
        id: number;
        name: string;
    };
    contact: {
        id: number;
        name: string;
    };
    owner: {
        id: number;
        name: string;
    };
    setEditSubmission: (editing: boolean) => void;
}

const FormEditSubmission = (props: Props) => {
    const [applyAll, setApplyAll] = useState(false);
    const { getFieldDecorator } = props.form;

    const { rfq_id, account, contact, owner } = props;
    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            const payload = {
                account: values.account.key,
                contact: values.contact.key,
                owner: values.owner.key,
                applyAll: values.applyAll,
            };
            if (!err) {
                patch(`staff/rfq/${rfq_id}/`, payload)
                    .then(() => {
                        notification['success']({
                            message: 'Updated',
                            description: 'RFQ was updated successfully',
                        });
                        props.setEditSubmission(false);
                    })
                    .catch((error: any) => {
                        notification.error({
                            message: 'Error',
                            description: <ErrorRender error={error} />,
                            style: {
                                width: 600,
                                marginLeft: 335 - 600,
                            },
                        });
                    });
                setApplyAll(false);
                props.form.resetFields();
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit} layout={'vertical'}>
            <Form.Item label="Account">
                {getFieldDecorator('account', {
                    rules: [{ required: false, message: 'Required' }],
                    ...(account && {
                        initialValue: {
                            key: account?.id,
                            label: account?.name,
                        },
                    }),
                })(<Accounts />)}
            </Form.Item>

            <Form.Item label="Contact">
                {getFieldDecorator('contact', {
                    rules: [{ required: false, message: 'Required' }],
                    ...(contact && {
                        initialValue: {
                            key: contact.id,
                            label: contact.name,
                        },
                    }),
                })(<Contacts />)}
            </Form.Item>

            <Form.Item label="Sales Rep">
                {getFieldDecorator('owner', {
                    rules: [{ required: false, message: 'Required' }],
                    ...(owner && {
                        initialValue: {
                            key: owner.id,
                            label: owner.name,
                        },
                    }),
                })(
                    <GenericSelect
                        onChange={() => setApplyAll(true)}
                        recordType={'User'}
                        filters={{
                            is_staff: true,
                            is_active: true,
                        }}
                        size="small"
                    />
                )}
            </Form.Item>

            {applyAll && (
                <Form.Item label="" help="">
                    {getFieldDecorator('applyAll', {
                        valuePropName: 'checked',
                    })(
                        <Checkbox className={'italic'}>
                            Apply Ownership changes also to the Contact And Account?
                        </Checkbox>
                    )}
                </Form.Item>
            )}

            <Form.Item className={'p-4'}>
                <Button className={'mr-4'} type={'primary'} htmlType="submit">
                    Save Changes
                </Button>
                <Button onClick={() => props.setEditSubmission(false)}>Cancel</Button>
            </Form.Item>
        </Form>
    );
};

export default Form.create()(FormEditSubmission);
