import React from 'react';
import { Tag } from 'antd';

interface Props {
    status: string;
}
const SalesOrderItemStatus = (props: Props) => {
    const { status } = props;

    const getColor = status => {
        switch (status) {
            case 'In Review':
                return 'blue';
            case 'Cancelled':
                return 'red';
            case 'Shipped Full':
                return 'green';
            case 'Shipped Partial':
                return 'orange';
            case 'Received in Full':
                return 'green';
            case 'Received in Partial':
                return 'orange';
            case 'Shipped In Partial to USA':
                return 'purple';
            case 'Shipped In Full to USA':
                return 'gold';
            case 'In Production':
                return 'gray';
            default:
                return 'default';
        }
    };

    return <Tag color={getColor(status)}>{status}</Tag>;
};

export default SalesOrderItemStatus;
