import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { fetchQuotes } from '../../redux/actions/quoteActions';
import { DateRender, ErrorRender, Price } from '@partsbadger/library';
import Stages from '../../components/Stages';
import { Badge, Button, Col, Divider, Row, Table, message } from 'antd';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
import { hasPermission } from '../../components/User';
import { QuoteFilterForm } from '../../components/QuoteTool';
import { get, post } from 'shared';
import { PaginationComponent } from 'components/Inputs/Pagination';
import { QuotePreview } from '@partsbadger/quote-tool';
import { SalesOrderStore } from '@partsbadger/sales_order_tool';
import { QuoteStore } from '../../stores';

const FileDownload = require('js-file-download');

const { Column } = Table;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const QuoteList = props => {
    const [previewQuoteId, setPreviewQuoteId] = useState();
    const [previewQuote, setPreviewQuote] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [downloadingQuote, setDownloadingQuote] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const quotes = useSelector(state => state.quotes);

    const searchParams = useQuery();

    useEffect(() => {
        document.addEventListener('keydown', closePreview);
    }, []);

    useEffect(() => {
        dispatch(fetchQuotes(searchParams));
        // eslint-disable-next-line react/prop-types
    }, [props.location.search]);

    const closePreview = () => {
        setPreviewQuote(false);
    };

    const downloadQuote = quote => {
        const url = `staff/quotes/${quote?.id}/export/`;
        setDownloadingQuote(quote.id);
        get(url, {}, { responseType: 'blob' })
            .then(response => {
                FileDownload(response.data, quote.name + '.pdf');
                setDownloadingQuote('');
            })
            .catch(error => {
                setDownloadingQuote('');
            });
    };

    return (
        <Fragment>
            <ErrorRender error={quotes.error} />
            <QuoteFilterForm />

            <Button
                className={'right'}
                style={{ display: selectedRowKeys.length > 0 ? 'inline' : 'none' }}
                type="primary"
            >
                <Link
                    to={{
                        pathname: '/orders/new',
                        state: {
                            quotes: selectedRowKeys,
                        },
                    }}
                >
                    Create Order
                </Link>
            </Button>

            <p className={'right'}>
                <Link
                    className={'ant-btn'}
                    to={{
                        pathname: '/quote/new',
                    }}
                >
                    Create Quote
                </Link>
            </p>

            <Divider>
                <div className={'title'}> Quotes</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table
                        rowKey={'id'}
                        dataSource={quotes.entities}
                        loading={quotes.isLoading}
                        pagination={false}
                        onRow={record => {
                            return {
                                // eslint-disable-next-line react/prop-types
                                onClick: () => props.history.push('/quotes/' + record.id),
                            };
                        }}
                        footer={() => {
                            return <PaginationComponent {...quotes.pagination_quotes} />;
                        }}
                        scroll={{ x: 1500 }}
                    >
                        <Column title="Id" dataIndex="id" key="id" />

                        <Column
                            title="Name"
                            dataIndex="name"
                            key="name"
                            render={(name, record) => {
                                return (
                                    <div className={'flex flex-col'}>
                                        {name}
                                        {record.is_dynamic && (
                                            <React.Fragment>
                                                <Divider type={'vertical'} />
                                                <Badge
                                                    count={'Dynamic'}
                                                    style={{
                                                        fontSize: 10,
                                                        height: 20,
                                                        backgroundColor: '#7576c4',
                                                    }}
                                                />
                                            </React.Fragment>
                                        )}

                                        {record.potential_bellringer && (
                                            <div className="flex items-center justify-center mt-2 bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
                                                <div>Potential bellringer</div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }}
                        />

                        <Column
                            title="Created Time"
                            dataIndex="created_time"
                            key="created_time"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <DateRender value={record.created_time} />
                                        <div className={'italic'}>By: {record.created_by.fullname}</div>
                                    </div>
                                );
                            }}
                        />

                        <Column title="Source" dataIndex="source" key="source" />

                        <Column
                            title="Completed Time"
                            dataIndex="completed_at"
                            key="completed_at"
                            render={(text, record) => {
                                return record.completed_at && moment(record.completed_at).format('LLL');
                            }}
                        />
                        <Column
                            title="Contact"
                            dataIndex="account"
                            key="account"
                            width={300}
                            render={(text, record) => {
                                return (
                                    <div>
                                        <div>{record.contact?.fullname}</div>
                                        <div className={'italic'}>{record.account?.name}</div>
                                    </div>
                                );
                            }}
                        />

                        <Column
                            title="RFQ"
                            dataIndex="itar"
                            key="itar"
                            render={(text, record) => {
                                return (
                                    record.rfq && (
                                        <a
                                            onClick={e => {
                                                e.stopPropagation();
                                            }}
                                            target="_blank"
                                            href={`https://groups.google.com/a/parts-badger.com/g/quotes/search?q=RFQ%20%23${record.rfq}%20for`}
                                            rel="noreferrer"
                                        >
                                            {typeof record.rfq === 'object' ? record.rfq.id : record.rfq}
                                        </a>
                                    )
                                );
                            }}
                        />

                        <Column title="Line Items" dataIndex="line_items_count" key="line_items_count" />
                        <Column
                            title="In Zoho"
                            dataIndex="zoho_id"
                            key="zoho_id"
                            render={(text, record) => {
                                if (record.zoho_id) {
                                    return (
                                        <a
                                            target={'_blank'}
                                            href={`${ZOHO_BASE_URL}/Quotes/${record.zoho_id}`}
                                            rel="noreferrer"
                                        >
                                            View{' '}
                                        </a>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Column
                            title="Design Engineer"
                            dataIndex="design_engineer"
                            key="design_engineer"
                            render={(text, record) => {
                                return (
                                    <Fragment>
                                        <p>{record?.design_engineer?.fullname}</p>
                                    </Fragment>
                                );
                            }}
                        />

                        <Column
                            title="Sales Rep"
                            dataIndex="owner"
                            key="owner"
                            render={(text, record) => {
                                return (
                                    <Fragment>
                                        <p>{record?.owner?.fullname}</p>
                                    </Fragment>
                                );
                            }}
                        />
                        <Column
                            title="Stage"
                            dataIndex="stage"
                            key="stage"
                            width={100}
                            render={(text, record) => {
                                return (
                                    <div className={'flex flex-col w-full'}>
                                        <div>
                                            <Stages stage={record.stage} />
                                        </div>
                                        {record.stage === 'Vendor Quoting' && (
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                                className={'w-full font-bold capitalize'}
                                            >
                                                {record.vendor_rfq_type}
                                            </div>
                                        )}
                                    </div>
                                );
                            }}
                        />

                        <Column
                            title="ITAR"
                            dataIndex="itar"
                            key="itar"
                            render={(text, record) =>
                                record.itar ? <Badge count={'ITAR'} style={{ backgroundColor: '#4b5320' }} /> : ''
                            }
                        />

                        <Column
                            title="Grand Total"
                            dataIndex="grand_total"
                            key="grand_total"
                            render={(t, r) => {
                                return <Price value={r.grand_total_with_shipping_cost} />;
                            }}
                        />
                        <Column
                            title="Action"
                            key="action"
                            width="200px"
                            render={(text, record) => (
                                <div>
                                    {hasPermission('quotes.view_quote') && (
                                        <div>
                                            <Link to={`quotes/${record.id}`}>View</Link>
                                        </div>
                                    )}

                                    <div
                                        className={'link preview'}
                                        onClick={e => {
                                            e.stopPropagation();
                                            setPreviewQuote(true);
                                            setPreviewQuoteId(record.id);
                                        }}
                                    >
                                        Preview
                                    </div>

                                    <div>
                                        <span
                                            className={'link'}
                                            onClick={e => {
                                                e.stopPropagation();
                                                downloadQuote(record);
                                            }}
                                        >
                                            {downloadingQuote === record.id ? 'Downloading' : 'Download PDF'}
                                        </span>
                                    </div>
                                    <div>
                                        <a
                                            onClick={e => {
                                                e.stopPropagation();
                                            }}
                                            target={'_blank'}
                                            href={`${ZOHO_BASE_URL}/CustomModule10/create`}
                                            rel="noreferrer"
                                        >
                                            Feedback
                                        </a>
                                    </div>

                                    <div>
                                        <a
                                            onClick={e => {
                                                e.stopPropagation();
                                            }}
                                            target={'_blank'}
                                            href={
                                                'https://portal.parts-badger.com/login?next=/my-account/quotes/' +
                                                record.id +
                                                '/modifications'
                                            }
                                            rel="noreferrer"
                                        >
                                            Dynamic quoting
                                        </a>
                                    </div>

                                    {record.completed && (
                                        <div>
                                            {hasPermission('salesorders.add_salesorder') && (
                                                <span
                                                    className={'link'}
                                                    onClick={async e => {
                                                        e.stopPropagation();
                                                        if (record.contact) {
                                                            await SalesOrderStore.getOrCreateSalesOrder(
                                                                record.contact.id
                                                            ).then(async () => {
                                                                await SalesOrderStore.update(SalesOrderStore.order_id, {
                                                                    quotes: [],
                                                                });
                                                                await SalesOrderStore.update(SalesOrderStore.order_id, {
                                                                    contact: record.contact.id,
                                                                    quotes: [record.id],
                                                                });
                                                                history.push('/sales-order/new/');
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Submit for an Order
                                                </span>
                                            )}
                                        </div>
                                    )}

                                    {record.completed && (
                                        <div>
                                            <span
                                                className={'link'}
                                                onClick={async e => {
                                                    e.stopPropagation();

                                                    QuoteStore.getRequote(record?.id).then(r => {
                                                        history.push('/get-quote/?uid=' + r.data.uid);
                                                    });
                                                }}
                                            >
                                                Requote
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                        />
                    </Table>

                    {previewQuote && <QuotePreview quote_id={previewQuoteId} handleClose={closePreview} />}
                </Col>
            </Row>
        </Fragment>
    );
};
