import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import api from '../utils/api';
import { TOTAL_PRICE_SHOW_CHECKLIST } from '../utils/utils';
import FileDownload from 'js-file-download';
import { get } from '@partsbadger/utils';
import axios from 'axios';
class SalesOrderStore {
    constructor() {
        this.loading = false;
        this.loading_quote_items = false;
        this.isUpdatingLineItem = false;
        this.account = null;
        this.contact = null;
        this.selectedContact = null;
        this.order = null;
        this.order_id = 0;
        this.quoted_line_items = []; // Line Items for the selected quotes
        this.applicationCostItems = [];
        this.order_items = []; // Local Line Items assigned to the order
        this.users = [];
        this.account_manager = [];
        this.addresses = null;
        this.default_shipping_address = null;
        this.default_billing_address = null;
        this.requirements_list = [];
        this.sales_order_notifications = [];
        this.error = null;
        this.quote = null;
        this.has_traveler_file = [];
        this.industry_use = null;
        this.taxes_rate = 0;
        this.default_requirements = [];
        this.item = [];
        this.poUnacceptableKeywords = [];
        this.afUnacceptableKeywords = [];
        this.creditCard = null;
        this.getAccountById = async (account_id) => {
            return await api.requests.get(`/customer/accounts/${account_id}/`);
        };
        this.getContactById = async (contact_id) => {
            await api.requests.get(`/customer/contacts/${contact_id}/`).then(data => {
                this.contact = data;
                this.account = data.account;
                this.selectedContact = data;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.default_shipping_address = this.contact.default_addresses.shipping_address;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.default_billing_address = this.contact.default_addresses.billing_address;
                // Todo Resolved in the back
                if (this.contact?.industry?.industry) {
                    this.industry_use = {
                        industry: {
                            key: this.contact.industry.industry.id.toString(),
                            label: this.contact.industry.industry.name,
                        },
                        industry_use: {
                            key: this.contact.industry.id.toString(),
                            label: this.contact.industry.use.name,
                        },
                        end_use: {
                            key: this.contact.industry.end_use,
                            label: this.contact.industry.end_use,
                        },
                    };
                }
                this.addresses = this.contact?.addresses;
            });
            await api.requests.get(`/customer/contacts/${contact_id}/additional-requirements-documents/`).then((data) => {
                this.default_requirements = data.requirements
                    ? data.requirements.map(r => {
                        return {
                            key: r.requirement.id,
                            label: r.requirement.name,
                            value: r.value,
                            files: r.files,
                            type: r.requirement.type,
                        };
                    })
                    : [];
            });
        };
        this.getTaxesByContactAndZipCode = async (contact_id, zip_code) => {
            await api.requests.get(`/customer/contacts/${contact_id}/tax-rate/?zip_code=${zip_code}`).then(data => {
                this.taxes_rate = data?.estimated_combined_rate || 0;
            });
        };
        this.getAdditionalRequirements = async () => {
            await api.requests.get(`/customer/additional-requirements/`).then(data => {
                this.requirements_list = data.results;
            });
        };
        makeObservable(this, {
            // Observables
            loading: observable,
            loading_quote_items: observable,
            isUpdatingLineItem: observable,
            account: observable,
            contact: observable,
            selectedContact: observable,
            order: observable,
            order_id: observable,
            quoted_line_items: observable,
            applicationCostItems: observable,
            order_items: observable,
            users: observable,
            account_manager: observable,
            addresses: observable,
            default_shipping_address: observable,
            default_billing_address: observable,
            requirements_list: observable,
            sales_order_notifications: observable,
            error: observable,
            quote: observable,
            industry_use: observable,
            taxes_rate: observable,
            default_requirements: observable,
            item: observable,
            poUnacceptableKeywords: observable,
            afUnacceptableKeywords: observable,
            creditCard: observable,
            has_traveler_file: observable,
            // Actions
            setLoading: action,
            setOrder: action,
            setAccount: action,
            setQuote: action,
            setOrderItems: action,
            setLineItemSampleQuantity: action,
            refactoringOfQuoteItems: action,
            addMultipleOrderItem: action,
            addOrderItem: action,
            updateOrderItem: action,
            updateOrderItem3D: action,
            updateOrderItemStepFile: action,
            updateOrderItem2D: action,
            removeOrderItem: action,
            getQuotes: action,
            getQuote: action,
            getUsers: action,
            setQuotedLineItems: action,
            appendApplicationCostItems: action,
            setApplicationCostItems: action,
            getQuoteLineItems: action,
            RemoveQuoteLineItemsFromSpecificQuote: action,
            sendToReview: action,
            saveOrCompleteSalesOrder: action,
            getOrCreateSalesOrder: action,
            getAccountById: action,
            getContactByEmail: action,
            getContactById: action,
            getTaxesByContactAndZipCode: action,
            getAdditionalRequirements: action,
            saveAddress: action,
            updateAddress: action,
            getOrderById: action,
            addRemoteOrderItem: action,
            addMultipleRemoteOrderItem: action,
            addRemoteOrderItemFormAdditionalRequirement: action,
            updateRemoteOrderItem: action,
            updateRemoteOrderItemWithOutPartnerShipDate: action,
            updateOrderLineItemsTagsLocal: action,
            updateOrderProcessesLocal: action,
            updateLineItemHardwareDomesticLocal: action,
            updateRemoteMultipleItems: action,
            updateRemoteOrderItemLocal: action,
            removeRemoteOrderItem: action,
            updateRemoteOrder: action,
            downloadAllFiles: action,
            uploadRedactedFile: action,
            update: action,
            sendToProduction: action,
            getQuotePDF: action,
            sendQuoteToZoho: action,
            analyze_3d_file: action,
            upload2dOr3dFile: action,
            getNotifications: action,
            sendNotification: action,
            getMarginLifetimeQuote: action,
            updateSalesOrderChecklist: action,
            updateSalesOrderSteps: action,
            recalculateSalesOrderTax: action,
            getOrderProcessingChecklist: action,
            getChecklistType: action,
            getChecklistsOrder: action,
            getSessionUser: action,
            updateAccountManager: action,
            getTotalPriceOrder: action,
            isBigOrder: action,
            getUnacceptableKeywords: action,
            getVendorQualificationsFromVendorApp: action,
            sendCreditCard: action,
            sendToShop: action,
            patchOrderDraft: action,
            sendToReviewStaff: action,
            update_order_item: action,
            sendToSalesRepresentative: action,
            update_sales_order_field: action,
            getHasTravelerFileSalesOrder: action,
            update3DApprovalStatus: action,
            // Computed
            available_quoted_line_items: computed,
            available_application_costs_items: computed,
            availableItemsCount: computed,
            pendingItemsToAddCount: computed,
            doWeNeed3DFiles: computed,
            doWeNeed2DFiles: computed,
            doWeNeedRedactedFiles: computed,
            getLeadItem: computed,
        });
    }
    setLoading(loading) {
        this.loading = loading;
    }
    setOrder(data) {
        this.order = data;
        if (data) {
            this.order_id = data.id;
            this.setOrderItems(data.line_items);
        }
        else {
            this.order = null;
        }
        this.loading = false;
    }
    setAccount(account) {
        this.account = account;
    }
    setQuote(quote) {
        this.quote = quote;
    }
    setOrderItems(order_items) {
        this.order_items = order_items.sort((a, b) => a.id - b.id);
    }
    async setBatchShipments(record, batch_shipments) {
        this.order_items = this.order_items.map(current => {
            if (record.id === current.id) {
                return {
                    ...current,
                    batch_shipments: batch_shipments,
                };
            }
            return current;
        });
        const payload = {
            ...record,
            batch_shipments: batch_shipments,
            split_into_batch_shipments: false,
        };
        await this.updateRemoteOrderItem(payload);
    }
    async setLineItemSampleQuantity(record, sample) {
        this.order_items = this.order_items.map(current => {
            if (record.id === current.id) {
                return {
                    ...current,
                    quantity_sample_required: sample,
                };
            }
            return current;
        });
        const payload = {
            ...record,
            quantity_sample_required: sample,
        };
        await this.updateRemoteOrderItem(payload);
    }
    refactoringOfQuoteItems(_quote_items) {
        const new_items = [];
        _quote_items.map(new_item => {
            let _quote_has_product_id = undefined;
            if (new_item.quote_has_product && new_item.quote_has_product._parent_id) {
                _quote_has_product_id = new_item.quote_has_product._parent_id;
            }
            else if (new_item.quote_has_product) {
                _quote_has_product_id = new_item.quote_has_product?.id;
            }
            const _item = {
                name: new_item.name,
                product_description: new_item.product_description,
                quote_has_product: _quote_has_product_id,
                quantity: new_item.quantity,
                unit_price: new_item.unit_price,
                total_price: Number(new_item.quantity) * Number(new_item.unit_price),
                required_part_ship_date: new_item.required_part_ship_date || null,
                required_partner_ship_date: new_item.required_partner_ship_date || null,
            };
            new_items.push(_item);
        });
        return new_items;
    }
    async addMultipleOrderItem(order_items) {
        this.loading = true;
        await this.addMultipleRemoteOrderItem(this.refactoringOfQuoteItems(order_items));
        if (this.order) {
            this.order_items = [];
            this.order?.line_items.map(order_item => {
                this.order_items = [order_item, ...this.order_items];
            });
        }
        this.loading = false;
        return true;
    }
    async addOrderItem(new_item) {
        this.order_items = [];
        let _quote_has_product_id = undefined;
        if (new_item.quote_has_product && new_item.quote_has_product._parent_id) {
            _quote_has_product_id = new_item.quote_has_product._parent_id;
        }
        else if (new_item.quote_has_product) {
            _quote_has_product_id = new_item.quote_has_product?.id;
        }
        const item = {
            name: new_item.name,
            product_description: new_item.product_description,
            quote_has_product: _quote_has_product_id,
            quantity: new_item.quantity,
            unit_price: new_item.unit_price,
            total_price: Number(new_item.quantity) * Number(new_item.unit_price),
            required_part_ship_date: new_item.required_part_ship_date || null,
            required_partner_ship_date: new_item.required_partner_ship_date || null,
        };
        await this.addRemoteOrderItem(item);
        if (this.order) {
            this.order?.line_items.map(order_item => {
                this.order_items = [order_item, ...this.order_items];
            });
        }
        return true;
    }
    /**
     * Update Line Item in local
     * @param record
     * @param quantity
     */
    async updateOrderItem(record, quantity) {
        this.order_items = this.order_items.map(current => {
            if (record.id === current.id) {
                return {
                    ...current,
                    quantity: quantity,
                    total: quantity * current.unit_price,
                };
            }
            return current;
        });
        const payload = {
            ...record,
            quantity: quantity,
        };
        await this.updateRemoteOrderItem(payload);
    }
    async updateOrderItemBatch(record, batches, split) {
        this.order_items = this.order_items.map(current => {
            if (record.id === current.id) {
                return {
                    ...current,
                };
            }
            return current;
        });
        const payload = {
            ...record,
            batch_shipments: batches,
            split_into_batch_shipments: split,
        };
        await this.updateRemoteOrderItem(payload);
    }
    /**
     * Update Line Item in local
     * @param record
     * @param production_3d_file_id
     * @param name_3d_file
     */
    async updateOrderItem3D(record, production_3d_file_id, name_3d_file, file3DApproval) {
        this.order_items = this.order_items.map(current => {
            if (record.id === current.id) {
                return {
                    ...current,
                    production_3d_file_id: production_3d_file_id,
                    name_3d_file: name_3d_file,
                };
            }
            return current;
        });
        const payload = {
            id: record.id,
            production_3d_file: production_3d_file_id,
            quantity: record.quantity,
            unit_price: record.unit_price,
        };
        if ('required_partner_ship_date' in record) {
            Object.assign(payload, {
                required_partner_ship_date: record.required_partner_ship_date,
            });
        }
        if (file3DApproval) {
            Object.assign(payload, {
                production_3d_file_approval_feedback: '',
                production_3d_file_approval_status: 'Need 3D Approval',
            });
        }
        this.order = await api.requests.patch(`/staff/sales-orders/${this.order?.id}/update-item/`, payload);
        if (this.order)
            this.setOrderItems(this.order.line_items);
        if (file3DApproval)
            await api.requests.post(`/staff/sales-orders/${this.order?.id}/files-approval-notification/`, payload);
    }
    async update3DApprovalStatus(lineItemId, newStatus) {
        const payload = {
            id: lineItemId,
            production_3d_file_approval_status: newStatus,
        };
        this.order = await api.requests.patch(`/staff/sales-orders/${this.order?.id}/update-item/`, payload);
    }
    /**
     * Update Line Item in local
     * @param order_item_id
     * @param production_3d_file
     */
    updateOrderItemStepFile(order_item_id, production_3d_file) {
        this.order_items = this.order_items.map(current => {
            if (order_item_id === current.id) {
                return {
                    ...current,
                    production_3d_file: production_3d_file,
                };
            }
            return current;
        });
        /**
         * This is necessary to display the thumbnail image of a new 3D file, the code should be refactoring and the
         * interfaces should be improved.
         */
        if (this.order && this.order.line_items) {
            this.order.line_items = this.order_items;
        }
    }
    /**
     * Update Line Item in local
     * @param record
     * @param production_2d_file_id
     * @param name_2d_file
     */
    async updateOrderItem2D(record, production_2d_file_id, name_2d_file) {
        this.order_items = this.order_items.map(current => {
            if (record.id === current.id) {
                return {
                    ...current,
                    production_2d_file_id: production_2d_file_id,
                    name_2d_file: name_2d_file,
                };
            }
            return current;
        });
        const payload = {
            id: record.id,
            production_2d_file: production_2d_file_id,
            quantity: record.quantity,
            unit_price: record.unit_price,
        };
        this.order = await api.requests.patch(`/staff/sales-orders/${this.order?.id}/update-item/`, payload);
        if (this.order)
            this.setOrderItems(this.order.line_items);
    }
    /***
     * Remove line item from local state
     * @param item
     */
    async removeOrderItem(item, i) {
        this.order_items.splice(i, 1);
        const items = this.order_items.map(item => {
            return item;
        });
        this.order_items = [];
        this.setOrderItems(items);
        await this.removeRemoteOrderItem(item);
    }
    async getQuotes(search) {
        await api.requests
            .get(`/staff/quotes/`, {
            params: {
                search: search,
            },
        })
            .then(data => {
            this.order = data.results;
        })
            .finally(() => (this.loading = false));
    }
    async getQuote(quote_id) {
        const response = await api.requests.get(`/staff/quotes/${quote_id}/`).finally(() => (this.loading = false));
        this.appendApplicationCostItems(quote_id, response.additional_requirements);
        this.quote = response;
        return response;
    }
    async getUsers(params) {
        await api.requests
            .get(`/staff/users/`, {
            params: params,
        })
            .then(data => {
            runInAction(() => {
                params.search === 'Account Managers'
                    ? (this.account_manager = data.results)
                    : (this.users = data.results);
            });
        });
    }
    setQuotedLineItems(data) {
        if (!data.length) {
            this.quoted_line_items = [];
            return;
        }
        // By Quote Has Product (Old Method)
        const new_items = data
            .filter(item => !item.has_dynamic_quantities)
            .filter(item => {
            return !this.quoted_line_items.find(li_item => li_item.id === item.id);
        });
        // Items by Quantity item (New Method)
        // Map Quantity Items as QuoteLineItems to keep sales order tool working
        let new_items_2 = [];
        data.filter(item => item.has_dynamic_quantities).forEach(quoted_item => {
            const formatted_items = quoted_item.quote_item_quantities
                .filter(item => item.displayed)
                .map(quantity_item => {
                return {
                    ...quoted_item,
                    id: 'quote_item_' + quantity_item.id,
                    _parent_id: quoted_item.id,
                    quantity: quantity_item.quantity,
                    unit_price: quantity_item.unit_price,
                    total_price: quantity_item.total_price,
                };
            });
            new_items_2 = [...new_items_2, ...formatted_items];
        });
        //remove existing items
        new_items_2 = new_items_2.filter(item => {
            return !this.quoted_line_items.find(li_item => li_item.id === item.id);
        });
        this.quoted_line_items = [...this.quoted_line_items, ...new_items, ...new_items_2];
    }
    appendApplicationCostItems(quote_id, additional_requirements) {
        const new_items = additional_requirements.map(item => {
            return {
                id: item.id,
                name: item.additional_requirement,
                price: item.price,
                quote: quote_id,
            };
        });
        const filtered__items = new_items.filter(new_item => {
            return !this.applicationCostItems.find(current => current.id === new_item.id);
        });
        this.setApplicationCostItems([...this.applicationCostItems, ...filtered__items]);
    }
    /**
     *
     * @param new_items
     */
    setApplicationCostItems(new_items) {
        this.applicationCostItems = new_items;
    }
    /**
     * Add Quoted line items of a specific quote
     */
    async getQuoteLineItems(quote_id) {
        this.loading_quote_items = true;
        await api.requests
            .get(`/customer/quotes/${quote_id}/line-items/`)
            .then(data => {
            this.setQuotedLineItems(data.results);
        })
            .finally(() => (this.loading_quote_items = false));
    }
    /**
     * Remove line items of a specific quote
     */
    RemoveQuoteLineItemsFromSpecificQuote(quote_id) {
        // Using == instead of === because sometimes is string and other number, yep is crazy
        this.quoted_line_items = this.quoted_line_items.filter(item => item.quote != quote_id);
        this.applicationCostItems = this.applicationCostItems.filter(item => item.quote != quote_id);
    }
    /**
     * Line items that are in the order yet
     */
    get available_quoted_line_items() {
        return this.quoted_line_items.filter(item => {
            return !this.order_items.some(current => {
                return item.id === current.quote_has_product?.id;
            });
        });
    }
    /**
     * Line items that are in the order yet
     */
    get available_application_costs_items() {
        return this.applicationCostItems.filter(item => {
            return !this.order_items.some(current => {
                return item.name === current.product_description && Number(item.price) === Number(current.unit_price);
            });
        });
    }
    get availableItemsCount() {
        return this.quoted_line_items.length + this.applicationCostItems.length;
    }
    get pendingItemsToAddCount() {
        return this.availableItemsCount - this.order_items.length;
    }
    async sendToReview(payload) {
        // Used only by the customer front-end
        this.loading = true;
        return await api.requests.post(`/customer/sales-orders/`, payload).finally(() => {
            this.loading = false;
        });
    }
    async saveOrCompleteSalesOrder(order_id, payload) {
        // Used only by the staff front-end
        this.loading = true;
        return await api.requests.patch(`/staff/sales-orders/${this.order_id}/`, payload);
    }
    async sendToReviewStaff(order_id, payload) {
        // Used only by the staff front-end
        this.loading = true;
        return await api.requests.post(`/staff/sales-orders/${this.order_id}/send-to-review/`, payload);
    }
    async getOrCreateSalesOrder(contact_id) {
        await get(`/staff/contacts/${contact_id}/get-orders-draft/`).then(response => {
            this.order_id = response.id;
            this.order = response;
            this.setOrderItems(response.line_items);
        });
    }
    async getContactByEmail(email) {
        await api.requests
            .get(`/customer/contacts/?email=${email}&exclude_account_payable=true`)
            .then(async (response) => {
            if (response.results[0]?.id)
                await this.getContactById(response.results[0]?.id);
        });
    }
    async saveAddress(contact_id, payload = {}) {
        return await api.requests.post(`/customer/contacts/${contact_id}/addresses/`, payload);
    }
    async updateAddress(contact_id, address_id, payload = {}) {
        return await api.requests.patch(`/customer/contacts/${contact_id}/addresses/${address_id}/`, payload);
    }
    get doWeNeed3DFiles() {
        return this.order_items.filter(item => item.quote_has_product && !item.production_3d_file).length > 0;
    }
    get doWeNeed2DFiles() {
        return this.order_items.filter(item => item.quote_has_product && !item.production_2d_file).length > 0;
    }
    get doWeNeedRedactedFiles() {
        if (!this.order) {
            return false;
        }
        return (this.order.master_products.filter(m => {
            return !m.drawing_file?.redacted_file || !m.step_file?.redacted_file;
        }).length > 0);
    }
    setBatchBlanketReleases() {
        if (!this.order) {
            return false;
        }
        if (this.order.shipping_requirements === null) {
            this.order.shipping_requirements = ['Batch/Blanket Releases'];
        }
        else {
            const new_shipping_requirements = [...this.order.shipping_requirements];
            new_shipping_requirements.push('Batch/Blanket Releases');
            this.order.shipping_requirements = new_shipping_requirements;
        }
    }
    removeBatchBlanketReleases() {
        if (!this.order) {
            return false;
        }
        if (this.order.shipping_requirements?.length > 0) {
            const new_shipping_requirements = [...this.order.shipping_requirements];
            this.order.shipping_requirements = new_shipping_requirements.filter(value => {
                return value !== 'Batch/Blanket Releases';
            });
        }
    }
    async getOrderById(sales_order_id) {
        this.loading = true;
        await api.requests
            .get(`/staff/sales-orders/${sales_order_id}/`)
            .then(data => {
            this.setOrder(data);
        })
            .finally(() => (this.loading = false));
    }
    /**
     * Add remote item based on quoted line item
     * @param payload
     */
    async addRemoteOrderItem(payload) {
        if (!this.order?.id)
            return;
        await api.requests.post(`/staff/sales-orders/${this.order?.id}/add-item/`, payload).then(data => {
            this.order = data;
        });
    }
    async patchOrderDraft(order_id, payload) {
        this.loading = false;
        return await api.requests
            .patch(`/staff/sales-orders/${order_id}/`, payload)
            .then(response => {
            this.order = response;
            // this.order_items = response.line_items;
        })
            .finally(() => {
            this.loading = false;
        });
    }
    async addMultipleRemoteOrderItem(payload) {
        if (!this.order?.id)
            return;
        await api.requests.post(`/staff/sales-orders/${this.order?.id}/add-multiple-items/`, payload).then(data => {
            this.order = data;
        });
    }
    /**
     * Add additional requirement to the sales orders as CNC Part
     * @param payload
     */
    async addRemoteOrderItemFormAdditionalRequirement(payload) {
        this.order = await api.requests.post(`/staff/sales-orders/${this.order?.id}/add-item/`, {
            name: 'CNC Part',
            ...payload,
        });
        return true;
    }
    /**
     * Update a item of a existing Sales Order
     */
    async updateRemoteOrderItem(order_line_item) {
        if (!this.order?.id)
            return;
        const payload = {
            id: order_line_item.id,
            quantity: order_line_item.quantity,
            unit_price: order_line_item.unit_price,
            required_part_ship_date: order_line_item.required_part_ship_date,
            required_partner_ship_date: order_line_item.required_partner_ship_date,
            vendor_qualifications: order_line_item.vendor_qualifications,
            hardware_required: order_line_item.hardware_required,
            domestic_process_needed: order_line_item.domestic_process_needed,
            domestic_processes: order_line_item.domestic_processes,
            hardwares_required: order_line_item.hardwares_required,
            batch_shipments: order_line_item.batch_shipments,
            quantity_sample_required: order_line_item.quantity_sample_required,
            split_into_batch_shipments: order_line_item.split_into_batch_shipments,
            partsbadger_production: order_line_item.partsbadger_production,
            partsbadger_production_method: order_line_item.partsbadger_production_method,
            discount: order_line_item.discount,
        };
        this.order.line_items = this.order.line_items.map(current => {
            if (order_line_item.id === current.id) {
                return order_line_item;
            }
            return current;
        });
        this.order = await api.requests.patch(`/staff/sales-orders/${this.order?.id}/update-item/`, payload);
    }
    async updateRemoteOrderItemWithOutPartnerShipDate(order_line_item) {
        if (!this.order?.id)
            return;
        const index = this.order.line_items.findIndex(obj => {
            return obj.id === order_line_item.id;
        });
        const payload = {
            id: order_line_item.id,
            quantity: order_line_item.quantity,
            unit_price: order_line_item.unit_price,
            required_part_ship_date: order_line_item.required_part_ship_date,
            required_partner_ship_date: this.order.line_items[index].required_partner_ship_date,
            hardware_required: order_line_item.hardware_required,
            domestic_process_needed: order_line_item.domestic_process_needed,
            domestic_processes: order_line_item.domestic_processes,
            hardwares_required: order_line_item.hardwares_required,
            // vendor_requirements: order_line_item.vendor_requirements,             //Todo Allow to update requirements one by one
            // vendor_requirement_notes: order_line_item.vendor_requirement_notes,
        };
        // this.order.line_items = this.order.line_items.map(current => {
        //     if (order_line_item.id === current.id) {
        //         return order_line_item;
        //     }
        //     return current;
        // });
        this.order = await api.requests.patch(`/staff/sales-orders/${this.order?.id}/update-item/`, payload);
    }
    updateOrderLineItemsTagsLocal(order_line_item, tags, type) {
        if (!this.order?.id)
            return;
        const itemIndex = this.order.line_items.findIndex(object => {
            return object.id === order_line_item?.id;
        });
        if (type === 'domestic') {
            this.order.line_items[itemIndex].domestic_processes = tags;
        }
        if (type === 'hardware') {
            this.order.line_items[itemIndex].hardwares_required = tags;
        }
    }
    updateOrderProcessesLocal() {
        if (!this.order?.id)
            return;
        const indexDomesticProcessNeeded = this.order.line_items.findIndex(object => {
            return object.domestic_process_needed === true;
        });
        if (indexDomesticProcessNeeded > -1)
            this.order.domestic_process_needed = true;
        const indexHardwareRequired = this.order.line_items.findIndex(object => {
            return object.hardware_required === true;
        });
        if (indexHardwareRequired > -1)
            this.order.hardware_required = true;
    }
    updateLineItemHardwareDomesticLocal(order_line_item, type, value) {
        if (!this.order?.id)
            return;
        const itemIndex = this.order.line_items.findIndex(object => {
            return object.id === order_line_item?.id;
        });
        if (type === 'domestic') {
            this.order.line_items[itemIndex].domestic_process_needed = value;
            return;
        }
        if (type === 'hardware') {
            this.order.line_items[itemIndex].hardware_required = value;
            return;
        }
    }
    /**
     * Update a item of a existing Sales Order
     */
    async updateRemoteMultipleItems(order_line_items) {
        if (!this.order?.id)
            return;
        const payload = order_line_items.map(order_line_item => {
            return {
                id: order_line_item.id,
                quantity: order_line_item.quantity,
                unit_price: order_line_item.unit_price,
                required_part_ship_date: order_line_item.required_part_ship_date,
                required_partner_ship_date: order_line_item.required_partner_ship_date,
                vendor_qualifications: order_line_item.vendor_qualifications,
                hardware_required: order_line_item.hardware_required,
                domestic_process_needed: order_line_item.domestic_process_needed,
                domestic_processes: order_line_item.domestic_processes,
                hardwares_required: order_line_item.hardwares_required,
                // vendor_requirements: order_line_item.vendor_requirements,             //Todo Allow to update requirements one by one
                // vendor_requirement_notes: order_line_item.vendor_requirement_notes,
            };
        });
        this.order.line_items = order_line_items;
        this.order = await api.requests.patch(`/staff/sales-orders/${this.order?.id}/update-multiple-items/`, payload);
    }
    async update_multiple_items(order_id, payload) {
        await api.requests.patch(`/staff/sales-orders/${order_id}/update-multiple-items/`, payload).then(data => {
            this.setOrder(data);
        });
        return this.order;
    }
    updateRemoteOrderItemLocal(order_line_item) {
        if (!this.order?.id)
            return;
        this.order.line_items = this.order.line_items.map(current => {
            if (order_line_item.id === current.id) {
                return order_line_item;
            }
            return current;
        });
    }
    /**
     * Remove line item of a existing Sales Order
     * @param item
     */
    async removeRemoteOrderItem(item) {
        if (!this.order)
            return;
        this.order.line_items = this.order.line_items.filter(current => item.id !== current.id);
        this.order = await api.requests.delete(`/staff/sales-orders/${this.order?.id}/remove-item/${item.id}/`);
        return true;
    }
    async updateRemoteOrder(order_id, payload) {
        this.loading = true;
        return await api.requests.patch(`/staff/sales-orders/${order_id}/`, payload).finally(() => {
            this.loading = false;
        });
    }
    /**
     * Makes an optimistic update in a specific field on the sales orders
     * @param key
     * @param value
     */
    async update_sales_order_field(key, value) {
        if (this.order) {
            this.order = {
                ...this.order,
                [key]: value,
            };
            const payload = {
                [key]: value,
            };
            await api.requests.patch(`/staff/sales-orders/${this.order.id}/`, payload).finally(() => {
                this.loading = false;
            });
        }
    }
    /**
     * Polls the backend to monitor the status of the "download all" task.
     *
     * @param {number} orderId - The ID of the sales order.
     * @param {string} taskId - The ID of the Celery task to monitor.
     * @param {number} interval - Polling interval in milliseconds (default: 2000ms).
     * @param {number} timeout - Maximum time to wait for task completion in milliseconds (default: 60000ms).
     * @returns {Promise<{ fileUrl: string, fileName: string }>} - Resolves with the file URL and file name on SUCCESS, rejects on FAILURE or timeout.
     */
    async pollTaskStatusForDownloadAll(orderId, taskId, interval = 5000, timeout = 300000) {
        const taskStatusEndpoint = `/staff/sales-orders/${orderId}/get-task-status-download-all/?task_id=${taskId}`;
        const startTime = Date.now();
        const checkTaskStatus = async () => {
            if (Date.now() - startTime > timeout) {
                throw new Error("Polling timed out while waiting for task completion.");
            }
            const response = await api.requests.get(taskStatusEndpoint);
            if (response.status === "SUCCESS") {
                // Task completed successfully, return file details
                return {
                    file_url: response.file_url,
                    file_name: response.file_name,
                };
            }
            else if (response.status === "FAILURE") {
                // Task failed
                throw new Error("Task failed: " + (response.error || "Unknown error"));
            }
            else {
                // Task is still processing, wait and retry
                await new Promise(resolve => setTimeout(resolve, interval));
                return checkTaskStatus(); // Recursive call
            }
        };
        return checkTaskStatus();
    }
    async downloadAllFiles(order_id) {
        try {
            // Step 1: Start the backend task to generate the ZIP file
            const { task_id: taskId } = await api.requests.get(`/staff/sales-orders/${order_id}/download-all/`, {});
            if (!taskId) {
                throw new Error("Failed to initiate the task. Task ID is missing.");
            }
            // Step 2: Poll the backend for task completion
            const { file_url: fileUrl, file_name: fileName } = await this.pollTaskStatusForDownloadAll(order_id, taskId);
            // Step 3: Download the file using the provided URL and name
            const response = await axios.get(fileUrl, { responseType: "blob" }); // Get the file as a blob
            FileDownload(response.data, fileName);
        }
        catch (error) {
            console.error("Error during the file download process:", error.message);
        }
    }
    async uploadRedactedFile(order_id, line_item_id, file_type, file) {
        const formData = new FormData();
        const filename = file_type === '2d' ? 'redacted_2d_file' : 'redacted_3d_file';
        formData.append('line_item', line_item_id);
        formData.append(filename, file);
        return await api.requests.post(`/staff/sales-orders/${order_id}/redacted-files/`, formData, {}, true);
    }
    async update(order_id, payload) {
        this.loading = true;
        return await api.requests.patch(`/staff/sales-orders/${order_id}/`, payload).finally(() => {
            this.loading = false;
            this.getOrderById(order_id);
        });
    }
    async sendToProduction(order_id, payload) {
        this.loading = true;
        return await api.requests
            .patch(`/staff/sales-orders/${order_id}/?send-to-production=true`, payload)
            .finally(() => {
            this.loading = false;
        });
    }
    get getLeadItem() {
        if (!this.order)
            return null;
        const lead_items = this.order.line_items
            .filter(item => item.suggested_lead_time)
            .map(item => item.suggested_lead_time)
            .sort(function (a, b) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            return b - a;
        });
        if (lead_items.length > 0) {
            return lead_items[0];
        }
        return null;
    }
    async getQuotePDF(quote_id) {
        return await api.requests.get(`/staff/quotes/${quote_id}/export/`, { responseType: 'blob' });
    }
    /**
     * Send pending quote to zoho
     * @param quote_id
     */
    async sendQuoteToZoho(quote_id) {
        return await api.requests.post(`/staff/quotes/${quote_id}/send-to-zoho/`, {}).finally(() => {
            this.loading = false;
        });
    }
    async analyze_3d_file(file_id) {
        await api.requests.post(`/staff/step-files/${file_id}/analyze/`, '').then(response => {
            console.log(response);
        });
    }
    async upload2dOr3dFile(file, type) {
        const url = type == '3D' ? '/customer/step-files/' : '/customer/drawing-files/';
        const formData = new FormData();
        formData.append('file', file);
        return await api.requests.post(url, formData, {}, true);
    }
    async getNotifications(sales_order_id) {
        await api.requests.get(`/staff/sales-orders/${sales_order_id}/notifications/`).then((response) => {
            this.sales_order_notifications = response;
        });
    }
    async sendNotification(sales_order_id, payload) {
        const formData = new FormData();
        if (payload.body) {
            formData.append('body', payload.body);
        }
        if (payload.attachments) {
            payload.attachments.forEach(item => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                formData.append('attachments_files', item);
            });
        }
        if (payload?.notify_owner) {
            formData.append('notify_owner', payload.notify_owner);
        }
        await api.requests
            .post(`/staff/sales-orders/${sales_order_id}/notifications/`, formData, 1, true)
            .then((response) => {
            this.sales_order_notifications = response;
        })
            .catch((error) => (this.error = error));
    }
    async getMarginLifetimeQuote(sales_order_id, payload = {}) {
        return await api.requests.get(`/staff/sales-orders/${sales_order_id}/margin-lifetime-quote/`, payload);
    }
    async updateSalesOrderChecklist(sales_order_id, params = {}) {
        return await api.requests.post(`/staff/sales-orders/${sales_order_id}/update-sales-order-checklist/`, params);
    }
    async updateSalesOrderSteps(sales_order_id, params = {}) {
        return await api.requests.patch(`/staff/sales-orders/${sales_order_id}/`, params);
    }
    async recalculateSalesOrderTax(sales_order_id) {
        const response = await api.requests.get(`/staff/sales-orders/${sales_order_id}/calculate-taxes-rate/`);
        this.order = response;
        return response;
    }
    async getOrderProcessingChecklist(sales_order_id) {
        return await api.requests.get(`/staff/sales-orders/${sales_order_id}/get-order-processing-checklist/`);
    }
    async getChecklistType(sales_order_id, name) {
        return await api.requests.get(`/staff/sales-orders/${sales_order_id}/get-checklist-type/?name=${name}`);
    }
    async getChecklistsOrder(sales_order_id) {
        return await api.requests.get(`/staff/sales-orders/${sales_order_id}/get-checklists-order/`);
    }
    async getSessionUser() {
        return await api.requests.get(`/rest-auth/user/`);
    }
    async updateAccountManager(sales_order_id, params = {}) {
        return await api.requests.post(`/staff/sales-orders/${sales_order_id}/update-account-manager/`, params);
    }
    updateMeetingTime(sales_order_id, params = {}) {
        return api.requests.post(`/staff/sales-orders/${sales_order_id}/update-meeting-time/`, params);
    }
    /**
     * Get total price sales order
     */
    getTotalPriceOrder() {
        const initialValue = 0;
        const total = this.order_items.reduce((accumulator, item) => {
            return accumulator + parseFloat(item.total.toString());
        }, initialValue);
        return total;
    }
    isBigOrder() {
        return (this.order?.subtotal ?? 0) > TOTAL_PRICE_SHOW_CHECKLIST;
    }
    async getUnacceptableKeywords(file_id, UnacceptableKeywords) {
        await api.requests
            .post(`/staff/files/${file_id}/get_unacceptable_keywords/`, {
            file_id: file_id,
        })
            .then(data => {
            data.unacceptable_keywords.map((k) => {
                if (UnacceptableKeywords.indexOf(k) === -1) {
                    UnacceptableKeywords.push(k);
                }
            });
        })
            .finally(() => (this.loading = false));
    }
    // get qualifications from vendor app
    async getVendorQualificationsFromVendorApp(sales_order_id) {
        return await api.requests.get(`/staff/sales-orders/${sales_order_id}/vendor-qualifications/`);
    }
    getProductTicketsFromVendorApp(sales_order_id) {
        return api.requests.get(`/vendor/v1/orders/${sales_order_id}/product-tickets/`);
    }
    //Credit card Submision store
    async sendCreditCard(payload) {
        const formData = new FormData();
        if (payload.amount) {
            formData.append('amount', payload.amount);
        }
        if (payload.customer_email) {
            formData.append('customer_email', payload.customer_email);
        }
        if (payload.notes) {
            formData.append('notes', payload.notes);
        }
        if (payload.representative_email) {
            formData.append('representative_email', payload.representative_email);
        }
        formData.append('ship_cost', payload.ship_cost);
        formData.append('tax_cost', payload.tax_cost);
        formData.append('zip_code', payload.zip_code);
        if (payload.quotes) {
            payload.quotes.forEach(item => {
                formData.append('quotes', item?.key);
            });
        }
        if (payload.files) {
            payload.files.forEach(item => {
                formData.append('files', item);
            });
        }
        await api.requests
            .post(`/staff/sales-orders/card/`, formData, 1, true)
            .then((response) => {
            this.creditCard = response;
        })
            .catch((error) => (this.error = error));
    }
    //Send to Shop Submision store
    async sendToShop(payload) {
        const formData = new FormData();
        if (payload.line_items) {
            formData.append('line_items', payload.line_items);
        }
        if (payload.customer_supplied_material) {
            formData.append('customer_supplied_material', payload.customer_supplied_material);
        }
        if (payload.notes) {
            formData.append('notes', payload.notes);
        }
        if (payload.files) {
            payload.files.forEach(item => {
                formData.append('files', item);
            });
        }
        await api.requests
            .post(`/staff/sales-orders/${payload.id}/send-to-shop/`, formData, {}, true)
            .then((response) => {
            response;
        })
            .catch((error) => (this.error = error));
    }
    async update_order_item(id, payload = {}) {
        await api.requests.patch(`/staff/sales-order-line-items/${id}/`, payload);
    }
    async sendToSalesRepresentative(id, payload) {
        return await api.requests.post(`/staff/sales-orders/${id}/send-to-sales-representative/`, payload);
    }
    async getHasTravelerFileSalesOrder(id) {
        return await api.requests.get(`/staff/sales-orders/${id}/has-traveler-file-so/`).then((response) => {
            this.has_traveler_file = response;
        });
    }
}
export default new SalesOrderStore();
