// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 *
 * HTTP URL and WEB SOCKETS URL
 */
import { env } from '../env';
export const URL_FRONTEND = env?.REACT_APP_URL_FRONTEND
    ? env.REACT_APP_URL_FRONTEND
    : 'https://quotes.parts-badger.com';
export const URL_PORTAL = env?.REACT_APP_URL_PORTAL ? env.REACT_APP_URL_PORTAL : 'https://portal.parts-badger.com';
export const QUICKTURN_API_ROOT = env?.REACT_APP_QUICKTURN_API_URL;
export const API_ROOT = env?.REACT_APP_API_URL ? env?.REACT_APP_API_URL : 'https://api.parts-badger.com';
export const BASE_URL_WS = API_ROOT.replace('http', 'ws');
export const GOOGLE_CLIENT_ID = '936736108436-06m75u4bvu4qrgfa9u9p8m8ehs2oh771.apps.googleusercontent.com';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyDZavt2tEQruj9qmuOUUAvzjjI4sdXopTk';
export const ZOHO_BASE_URL = env?.REACT_APP_ZOHO_URL
    ? env.REACT_APP_ZOHO_URL
    : 'https://crm.zoho.com/crm/org633043268/tab';
/**
 * Square Payment
 */
export const SQUARE_APP_ID = env?.REACT_APP_SQUARE_APP_ID
    ? env.REACT_APP_SQUARE_APP_ID
    : 'sq0idp-uB3It19uQ4b4DZ72Q33f6A';
export const SQUARE_LOCATION_ID = env?.REACT_APP_SQUARE_LOCATION_ID
    ? env.REACT_APP_SQUARE_LOCATION_ID
    : 'B2YZK1TKA3VKQ';
export const SQUARE_ENVIRONMENT_URL = env?.REACT_APP_SQUARE_ENVIRONMENT_URL
    ? env.REACT_APP_SQUARE_ENVIRONMENT_URL
    : 'https://web.squarecdn.com/v1/square.js';
// Custom environment variable to add additional tools
export const REACT_APP_ENVIRONMENT = env?.REACT_APP_ENVIRONMENT ? env.REACT_APP_ENVIRONMENT : 'development';
export const VENDOR_API_URL = process?.env?.REACT_APP_VENDOR_API_URL;
