import React, { useState } from 'react';
import { Button, Input, InputNumber, notification, Form } from 'antd';
import { observer } from 'mobx-react';
import { Coatings, Finishes, InputQuantities, Materials, Processes } from './DropDowns';
import QuoteStore from '../stores/QuoteStore';
import { ErrorRender, FormErrorParser } from '@partsbadger/library';
import PlasticMaterialPreferencesModal from '../components/QuoteLineItem/PlasticMaterialPreferencesModal';
function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
const BulkUpdateItems = ({ master_products, for_staff = false, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [showQuantity, setShowQuantity] = useState(true);
    const [showMaterialColorModal, setShowMaterialColorModal] = useState(null);
    const [form] = Form.useForm();
    const { getFieldValue } = form;
    const handleSubmit = async () => {
        form.validateFields()
            .then(async (values) => {
            setLoading(true);
            const data = {
                product: {
                    customs: {},
                },
            };
            if (values.manufacturing_type) {
                data['manufacturing_type'] = values.manufacturing_type;
            }
            if (values?.finish) {
                data['product']['finishes'] = [values.finish.key];
            }
            if (values?.material) {
                data['product']['material'] = values.material.key;
            }
            if (values.custom_material) {
                data['product']['custom_material'] = values.custom_material;
            }
            if (values.custom_finish) {
                data['product']['custom_finish'] = values.custom_finish;
            }
            if (values?.coating) {
                data['product']['coatings'] = [values.coating.key];
            }
            if (values.custom_coating) {
                data['product']['custom_coating'] = values.custom_coating;
            }
            if (values.quantity) {
                data['quantity'] = values.quantity;
                data['quantities'] = [];
            }
            if (values.quantities) {
                data['quantity'] = 1;
                data['quantities'] = values.quantities;
            }
            if (QuoteStore.quote) {
                const payload = QuoteStore?.quote?.line_items
                    .filter(item => master_products.includes(item.product.master_product))
                    .map(item => {
                    if (item.product.has_file_3d &&
                        item.product.attachments.file3d.filename &&
                        data.manufacturing_type) {
                        const type = item.product.attachments.file3d.filename.split('.').pop();
                        if (type?.toLowerCase() === 'stl') {
                            return {
                                ...data,
                                id: item.id,
                                manufacturing_type: '3D PRINTING',
                            };
                        }
                    }
                    return {
                        ...data,
                        id: item.id,
                    };
                });
                await QuoteStore.updateMultipleLineItems(payload)
                    .then(() => {
                    form.resetFields();
                    props.handleClose();
                })
                    .catch((err) => {
                    notification.error({
                        message: React.createElement(ErrorRender, { error: err }),
                        placement: 'topRight',
                    });
                });
            }
            setLoading(false);
        })
            .catch(errorInfo => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Please input all required fields',
                description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                placement: 'top',
                maxCount: 1,
            });
        });
    };
    const handleOnSelectMaterial = e => {
        const materialLabel = e?.label || '';
        const materialId = e?.key;
        const containsClearInLabel = materialLabel.includes('Clear');
        if (containsClearInLabel) {
            const isPlasticMaterial = QuoteStore.materials?.some(material => material.id == materialId && material.material_type == 'Plastic');
            if (isPlasticMaterial) {
                setShowMaterialColorModal('transparent');
                return true;
            }
        }
        const isColoredPlasticMaterial = QuoteStore.materials?.some(material => material.id == materialId && material.material_type === 'Plastic' && material.colored === false);
        if (isColoredPlasticMaterial) {
            setShowMaterialColorModal('colored');
            return true;
        }
    };
    const handleModalCancel = () => {
        setShowMaterialColorModal(null);
        form.resetFields(['material', 'isTransparent']);
    };
    const handleModalSubmit = () => {
        setShowMaterialColorModal(null);
        if (getFieldValue('isTransparent') == 'yes') {
            const finish = QuoteStore.finishes?.find(f => f.name === 'Flame Polish');
            form.setFieldsValue({ finish: { key: finish?.id, label: finish?.name } });
        }
        if (getFieldValue('preferredColor')) {
            const materialName = getFieldValue('material')?.label;
            const customMaterial = `${materialName} ${getFieldValue('preferredColor')}`;
            const material_values = QuoteStore.materials?.find(f => f.name === 'Custom');
            form.setFieldsValue({
                material: { key: material_values?.id, label: material_values?.name },
                custom_material: customMaterial,
            });
        }
        form.resetFields(['isTransparent', 'preferredColor']);
    };
    return (React.createElement(Form, { form: form, className: "form-bulk-update", layout: 'vertical', onFinish: () => handleSubmit(), onFinishFailed: errorInfo => {
            notification.error({
                message: 'Error',
                description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
            });
        } },
        React.createElement(Form.Item, { label: 'Process' },
            React.createElement(Form.Item, { name: "manufacturing_type", rules: [{ required: false, message: 'Please select a process!' }], noStyle: true },
                React.createElement(Processes, { placeholder: "Select", style: {
                        width: '100%',
                        minWidth: 100,
                    } }))),
        React.createElement(Form.Item, { label: 'Material' },
            React.createElement(Form.Item, { name: "material", rules: [{ required: false, message: 'Please select a material!' }], noStyle: true },
                React.createElement(Materials, { onSelect: handleOnSelectMaterial, materials: QuoteStore.materials })),
            React.createElement(Form.Item, { noStyle: true, shouldUpdate: (prevValues, curValues) => prevValues.material !== curValues.material }, ({ getFieldValue }) => getFieldValue('material')?.label === 'Custom' ? (React.createElement(Form.Item, { name: "custom_material", label: 'Material Name', rules: [{ required: true, message: 'Please insert a value' }] },
                React.createElement(Input, { size: "small" }))) : null)),
        React.createElement(Form.Item, { label: 'Finish' },
            React.createElement(Form.Item, { name: "finish", rules: [{ required: false, message: 'Please select a finish!' }], noStyle: true },
                React.createElement(Finishes, { finishes: QuoteStore.finishes })),
            React.createElement(Form.Item, { noStyle: true, shouldUpdate: (prevValues, curValues) => prevValues.finish !== curValues.finish }, ({ getFieldValue }) => getFieldValue('finish')?.label === 'Custom' ? (React.createElement(Form.Item, { name: "custom_finish", label: 'Finish Name', rules: [{ required: true, message: 'Please insert a value' }] },
                React.createElement(Input, { size: "small" }))) : null)),
        React.createElement(Form.Item, { label: 'Coating' },
            React.createElement(Form.Item, { name: "coating", rules: [{ required: false, message: 'Please select a coating!' }], noStyle: true },
                React.createElement(Coatings, { coatings: QuoteStore.coatings })),
            React.createElement(Form.Item, { noStyle: true, shouldUpdate: (prevValues, curValues) => prevValues.coating !== curValues.coating }, ({ getFieldValue }) => getFieldValue('coating')?.label === 'Custom' ? (React.createElement(Form.Item, { name: "custom_coating", label: 'Coating Name', rules: [{ required: true, message: 'Please insert a value' }] },
                React.createElement(Input, { size: "small" }))) : null)),
        for_staff && (React.createElement(React.Fragment, null, showQuantity ? (React.createElement(Form.Item, { labelCol: { span: 24 }, label: 'Quote Single Quantity' },
            React.createElement(Form.Item, { name: 'quantity', noStyle: true },
                React.createElement(InputNumber, { step: 1, controls: false, style: { width: '100%' } })),
            React.createElement(Button, { type: 'link', onClick: () => setShowQuantity(false) }, "or Quote Multiple Quantities?"))) : (React.createElement(Form.Item, { labelCol: { span: 24 }, label: 'Quote Multiple Quantities' },
            React.createElement(Form.Item, { name: 'quantities', noStyle: true },
                React.createElement(InputQuantities, { style: { width: '100%' } })),
            React.createElement(Button, { type: 'link', onClick: () => setShowQuantity(true) }, "or Quote Single Quantity?"))))),
        !for_staff && !QuoteStore.isRequote && (React.createElement(Form.Item, { name: "quantity", label: 'Quantity', rules: [{ required: false, message: 'Please input a quantity!' }] },
            React.createElement(InputNumber, null))),
        React.createElement(Form.Item, null,
            React.createElement(Button, { type: "primary", block: true, loading: loading, htmlType: "submit" }, "Update All")),
        showMaterialColorModal && (React.createElement(PlasticMaterialPreferencesModal, { showMaterialColorModal: showMaterialColorModal, handleSubmit: handleModalSubmit, handleModalCancel: handleModalCancel }))));
};
export default observer(BulkUpdateItems);
