import React from 'react';
import { Tag } from 'antd';

interface Props {
    shipping_status: string;
}
const ShippingStatus = (props: Props) => {
    if (props.shipping_status == 'Shipped in Partial') return <Tag color={'warning'}>Shipped Partial</Tag>;

    if (props.shipping_status == 'Shipped in Full') return <Tag color={'success'}>Shipped Full</Tag>;

    return <Tag color={'default'}>Pending</Tag>;
};

export default ShippingStatus;
