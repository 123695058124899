import React from 'react';
import { Tag } from 'antd';

interface Props {
    status: string;
}

const ProductionBiddingStatus = (props: Props) => {
    const { status } = props;

    const getColor = status => {
        switch (status) {
            case 'OPEN':
                return 'blue';
            case 'DECLINED TO BID':
                return 'red';
            case 'REJECTED BY STAFF':
                return 'red';
            case 'AWAITING CONFIRMATION':
                return 'orange';
            case 'VENDOR DECLINED':
                return 'red';
            case 'VENDOR CONFIRMED':
                return 'green';
            case 'VENDOR DENIED':
                return 'red';
            case 'VENDOR CANCELLED':
                return 'red';
            default:
                return 'blue';
        }
    };

    return <Tag color={getColor(status)}>{status}</Tag>;
};

export default ProductionBiddingStatus;
