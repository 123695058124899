import React, { useState } from 'react';
import { Modal, Form, Radio, Input } from 'antd';
const PlasticMaterialPreferencesModal = props => {
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const { showMaterialColorModal, handleSubmit, handleModalCancel, fieldName } = props;
    const form = Form.useFormInstance();
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { title: "Plastic Material Preferences", open: !!showMaterialColorModal, onOk: handleSubmit, onCancel: handleModalCancel, destroyOnClose: true, okButtonProps: {
                disabled: isSubmitButtonDisabled,
            }, okText: "Submit" }, showMaterialColorModal == 'transparent' ? (React.createElement(Form, { form: form, layout: "vertical" },
            React.createElement(Form.Item, { name: fieldName ? fieldName('isTransparent') : 'isTransparent', label: "Would you like the part to be optically clear (transparent)?" },
                React.createElement(Radio.Group, { onChange: () => {
                        setIsSubmitButtonDisabled(false);
                    } },
                    React.createElement(Radio, { value: "yes" }, "Yes"),
                    React.createElement(Radio, { value: "no" }, "No"))))) : (React.createElement(React.Fragment, null,
            React.createElement(Form.Item, { name: fieldName ? fieldName('preferredColor') : 'preferredColor', label: "Please enter your preferred color (white, black, natural, etc.):", rules: [{ required: true, message: 'Please enter a color.' }] },
                React.createElement(Input, { onChange: e => {
                        setIsSubmitButtonDisabled(e.target.value.trim() === '');
                    } })))))));
};
export default PlasticMaterialPreferencesModal;
