import React from 'react';
import { Badge, Button, Card, Col, Descriptions, Divider, Image, List, Row, Statistic, Tag, Typography } from 'antd';
import { ISalesOrderLineItemResponse } from '../../stores/SalesOrderLineItemStore';
import { ArrowUpOutlined, FileOutlined, FilePdfOutlined } from '@ant-design/icons';
import QuantityDisplay from './QuantityDisplay';
import ShippingStatus from './ShippingStatus';

interface Props {
    lineItem: ISalesOrderLineItemResponse;
}

const Text = Typography.Text;

const SalesOrderLinItemDetails = (props: Props) => {
    const { lineItem } = props;
    const product = lineItem;
    return (
        <>
            <Card
                style={{ width: '100%' }}
                title={
                    <div>
                        <Text>Ship By:</Text>
                        &nbsp;
                        <Text
                            italic={!!lineItem.revised_partner_ship_date}
                            delete={!!lineItem.revised_partner_ship_date}
                            style={{
                                fontWeight: lineItem.revised_partner_ship_date ? 'normal' : 'bold',
                            }}
                        >
                            {lineItem.required_part_ship_date?.toString() || 'N/A'}
                        </Text>
                        {lineItem.revised_partner_ship_date ? (
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    marginLeft: '8px',
                                }}
                            >
                                {lineItem.revised_partner_ship_date?.toString() || 'N/A'}
                            </Text>
                        ) : null}
                    </div>
                }
            >
                <Row gutter={[16, 16]} align="middle" justify="center">
                    <Col xs={24} sm={8} md={6} style={{ textAlign: 'center' }}>
                        <Image
                            onClick={() => {
                                if (product.production_3d_file) {
                                    const url = `/step-file-viewer/${product.production_3d_file.id}/`;
                                    window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
                                }
                            }}
                            src={product.production_3d_file?.image ?? ''}
                            alt="No Image Available"
                            width={150}
                            height={150}
                            preview={false}
                            style={{
                                objectFit: 'cover',
                                borderRadius: '8px',
                                cursor: product.production_3d_file ? 'pointer' : 'default',
                            }}
                            placeholder={
                                <div
                                    style={{
                                        width: 150,
                                        height: 150,
                                        backgroundColor: '#f0f0f0',
                                        borderRadius: '8px',
                                    }}
                                />
                            }
                        />

                        <div style={{ marginTop: '16px' }}>
                            <Button
                                type="link"
                                target={'_blank'}
                                href={product.production_3d_file?.filename || undefined}
                                disabled={!product.production_3d_file?.filename}
                            >
                                <FileOutlined /> STEP
                            </Button>
                            <Button
                                type="link"
                                target={'_blank'}
                                href={product.production_2d_file?.file || undefined}
                                disabled={!product.production_2d_file?.file}
                            >
                                <FilePdfOutlined /> PDF
                            </Button>
                        </div>
                    </Col>

                    {/* Info Column */}
                    <Col xs={24} sm={16} md={18}>
                        <Divider dashed />

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <strong>Quantity:</strong>{' '}
                                <QuantityDisplay
                                    quantity={product.quantity}
                                    originalQuantity={product.original_quantity}
                                />
                            </Col>
                            <Col span={12}>
                                <strong>Material:</strong>
                                <span>{product.quote_has_product?.product?.material?.name}</span>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <strong>Coating:</strong>
                                <span>{product.quote_has_product?.product?.coating?.name}</span>
                            </Col>
                            <Col span={12}>
                                <strong>Surface Finish:</strong>
                                <span>{product.quote_has_product?.product?.finish?.name}</span>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}></Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <strong>Unit Price:</strong> ${product.unit_price}
                            </Col>
                            <Col span={12}>
                                <strong>Total Price:</strong> ${product.total}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>

            <br />

            <Card
                title={
                    <>
                        <Text>Shipping Information</Text>
                        &nbsp;
                        <ShippingStatus shipping_status={lineItem.shipping_status} />
                        {/*{lineItem.date_shipped_in_full ? (*/}
                        {/*    <Tag color="success" style={{ marginLeft: '8px' }}>*/}
                        {/*        on {lineItem.date_shipped_in_full.toLocaleDateString()}*/}
                        {/*    </Tag>*/}
                        {/*) : null}*/}
                    </>
                }
                bordered={true}
            >
                <Row gutter={16}>
                    <Col span={8}>
                        <Card bordered={false}>
                            <Statistic
                                title="Stock in Inventory"
                                value={lineItem.stock}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                                // prefix={<ArrowUpOutlined />}
                                // suffix="%"
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                            <Statistic
                                title="Quantity Shipped"
                                value={lineItem.quantity_shipped}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                                // prefix={<ArrowUpOutlined />}
                                // suffix="%"
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                            <Statistic
                                title="Quantity Remaining"
                                value={lineItem.quantity_remaining_to_ship}
                                precision={0}
                                valueStyle={{ color: '#cf1322' }}
                                // prefix={<ArrowDownOutlined />}
                                // suffix="%"
                            />
                        </Card>
                    </Col>
                </Row>

                {/*<Descriptions column={1}>*/}
                {/*    <Descriptions.Item label="Revised Ship Date">*/}
                {/*        {lineItem.revised_partner_ship_date?.toString() || 'N/A'}*/}
                {/*    </Descriptions.Item>*/}

                {/*    /!*<Descriptions.Item label="Date Shipped in Full">*!/*/}
                {/*    /!*    {lineItem.date_shipped_in_full ?? 'Not available'}*!/*/}
                {/*    /!*</Descriptions.Item>*!/*/}
                {/*    /!*<Descriptions.Item label="Date Received in Full">*!/*/}
                {/*    /!*    {lineItem.date_received_in_full ?? 'Not available'}*!/*/}
                {/*    /!*</Descriptions.Item>*!/*/}
                {/*</Descriptions>*/}
            </Card>

            <br />

            {/*<Card title="In House Requirements">*/}
            {/*    <Descriptions column={1} bordered>*/}
            {/*        <Descriptions.Item label="PartsBadger Production">*/}
            {/*            {lineItem.partsbadger_production ? 'Yes' : 'No'}*/}
            {/*        </Descriptions.Item>*/}
            {/*        <Descriptions.Item label="In-House Requirements">*/}
            {/*            <List*/}
            {/*                dataSource={lineItem.in_house_requirements || []}*/}
            {/*                renderItem={item => <List.Item>{item}</List.Item>}*/}
            {/*            />*/}
            {/*        </Descriptions.Item>*/}
            {/*        <Descriptions.Item label="In-House Requirement Notes">*/}
            {/*            {lineItem.in_house_requirement_notes ?? 'No notes'}*/}
            {/*        </Descriptions.Item>*/}

            {/*        <Descriptions.Item label="Hardware Required">*/}
            {/*            {lineItem.hardware_required ? 'Yes' : 'No'}*/}
            {/*        </Descriptions.Item>*/}
            {/*        <Descriptions.Item label="Domestic Process Needed">*/}
            {/*            {lineItem.domestic_process_needed ? 'Yes' : 'No'}*/}
            {/*        </Descriptions.Item>*/}
            {/*        <Descriptions.Item label="Domestic Processes">*/}
            {/*            <List*/}
            {/*                dataSource={lineItem.domestic_processes || []}*/}
            {/*                renderItem={item => <List.Item>{item}</List.Item>}*/}
            {/*            />*/}
            {/*        </Descriptions.Item>*/}
            {/*        <Descriptions.Item label="Hardwares Required">*/}
            {/*            <List*/}
            {/*                dataSource={lineItem.hardwares_required || []}*/}
            {/*                renderItem={item => <List.Item>{item}</List.Item>}*/}
            {/*            />*/}
            {/*        </Descriptions.Item>*/}

            {/*        <Descriptions.Item label="Production Team Requirements">*/}
            {/*            <List*/}
            {/*                dataSource={lineItem.production_team_requirements || []}*/}
            {/*                renderItem={item => <List.Item>{item}</List.Item>}*/}
            {/*            />*/}
            {/*        </Descriptions.Item>*/}
            {/*    </Descriptions>*/}
            {/*</Card>*/}

            <br />

            {/*<Card title="Production Requirements">*/}
            {/*    <Descriptions column={1} bordered>*/}
            {/*        <Descriptions.Item label="Vendor Qualifications">*/}
            {/*            <List*/}
            {/*                dataSource={lineItem.vendor_qualifications || []}*/}
            {/*                renderItem={item => <List.Item>{item}</List.Item>}*/}
            {/*            />*/}
            {/*        </Descriptions.Item>*/}
            {/*        <Descriptions.Item label="Vendor Requirements">*/}
            {/*            <List*/}
            {/*                dataSource={lineItem.vendor_requirements || []}*/}
            {/*                renderItem={item => <List.Item>{item}</List.Item>}*/}
            {/*            />*/}
            {/*        </Descriptions.Item>*/}
            {/*        <Descriptions.Item label="Vendor Requirement Notes">*/}
            {/*            {lineItem.vendor_requirement_notes ?? 'No notes'}*/}
            {/*        </Descriptions.Item>*/}

            {/*        <Descriptions.Item label="First Article Approval">*/}
            {/*            {lineItem.first_article_approval ? 'Required' : 'Not Required'}*/}
            {/*        </Descriptions.Item>*/}
            {/*        <Descriptions.Item label="Batch Shipments">*/}
            {/*            <List*/}
            {/*                dataSource={lineItem.batch_shipments || []}*/}
            {/*                renderItem={shipment => (*/}
            {/*                    <List.Item>*/}
            {/*                        Quantity: {shipment.quantity}, Required Date: {shipment.required_part_ship_date}*/}
            {/*                    </List.Item>*/}
            {/*                )}*/}
            {/*            />*/}
            {/*        </Descriptions.Item>*/}
            {/*        <Descriptions.Item label="Sample Approval Status">*/}
            {/*            {lineItem.sample_approval_status || 'Pending'}*/}
            {/*        </Descriptions.Item>*/}
            {/*        <Descriptions.Item label="Production Status">*/}
            {/*            {lineItem.production_status || 'Not Started'}*/}
            {/*        </Descriptions.Item>*/}
            {/*        <Descriptions.Item label="Production 3D File Approval Status">*/}
            {/*            {lineItem.production_3d_file_approval_status || 'Pending'}*/}
            {/*        </Descriptions.Item>*/}
            {/*    </Descriptions>*/}
            {/*</Card>*/}
        </>
    );
};

export default SalesOrderLinItemDetails;
