import React from 'react';
import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';

interface DocCertificateStatusProps {
    isUploaded: boolean;
    isApproved: boolean;
    requirementStatus: string;
    statusColor: string;
    textColor: string;
}

const DocCertificateStatus: React.FC<DocCertificateStatusProps> = ({
    isUploaded,
    isApproved,
    requirementStatus,
    statusColor,
    textColor,
}) => {
    const title = !isUploaded
        ? 'Waiting for the user to upload a specific document before proceeding further.'
        : !isApproved
        ? 'The document has been uploaded but is awaiting approval.'
        : 'The document has been uploaded and approved.';

    const icon = !isUploaded ? (
        <ExclamationCircleOutlined
            style={{
                color: statusColor,
            }}
        />
    ) : !isApproved ? (
        <ClockCircleOutlined
            style={{
                color: statusColor,
            }}
        />
    ) : (
        <CheckCircleOutlined
            style={{
                color: statusColor,
            }}
        />
    );

    return (
        <Tooltip title={<p style={{ textAlign: 'justify' }}>{title}</p>}>
            <Tag color={statusColor} style={{ color: textColor }} icon={icon}>
                {requirementStatus}
            </Tag>
        </Tooltip>
    );
};

export default DocCertificateStatus;
