import React, { useState } from 'react';

import { Modal, Button, Table, Divider, Empty, Select, Checkbox, message, InputNumber, notification } from 'antd';
import { ColumnProps } from 'antd/es/table';

import { OrderLineItemProps } from '@partsbadger/types';

import { Price } from '@partsbadger/library';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

interface IProps {
    showCloneOrderModal: boolean;
    onClose: () => void;
}

const CloneOrderModal: React.FC<IProps> = props => {
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('DS');

    const history = useHistory();

    const { showCloneOrderModal, onClose } = props;
    const order = SalesOrderStore.cloneOrder;

    const valueWithFullDiscount = 0;

    const handleSubmit = async () => {
        const payload = {
            type: type,
            line_items: order?.line_items?.map(item => {
                return { sales_order_item: item.id, quantity: item.quantity, discount: item.discount };
            }),
        };

        if (order?.id) {
            setLoading(true);
            await SalesOrderStore.createCloneOrder(order?.id, payload)
                .then(data => {
                    setLoading(false);
                    notification.success({
                        message: 'The order was cloned successfully.',
                        placement: 'topRight',
                    });

                    history.push(`/sales-order/${data.id}/`);
                })
                .catch((err: any) => {
                    setLoading(false);

                    notification.error({
                        message: 'Failed to clone the order. Please try again.',
                        placement: 'topRight',
                    });
                });
        }
    };

    const Property = (props: { label: string; value: string }) => {
        const { label, value } = props;

        return (
            <div className={'flex flex-row'}>
                <div className={'font-bold'}>{label}:</div>
                <div className={'font'}>{value}</div>
            </div>
        );
    };

    const columns: ColumnProps<OrderLineItemProps>[] = [
        {
            title: 'Preview',
            dataIndex: 'image',
            width: 200,
            render: (t, record) => {
                const image = record.production_3d_file?.image;
                return <img width={100} src={image} />;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            filters: order?.line_items?.map(item => {
                return { text: item.name, value: item.id };
            }),
            filterSearch: true,
            onFilter: (value, record) => record.id == value,
            width: 200,
        },
        {
            title: 'Product Details',
            dataIndex: 'name',
            render: (t, record) => {
                if (record.name === 'CNC Part') {
                    return `${record.name} ${record.product_description ?? ''}`;
                }

                const product = record.quote_has_product?.product;

                return (
                    <div className={'flex flex-col'}>
                        <div className={'flex flex-col'}>
                            <div className={'font-bold'}>Dimensions:</div>
                            <div className="italic text-xs">{product?.dimensions}</div>
                        </div>
                        <div className={'flex flex flex-col'}>
                            {product?.material && <Property label="Material" value={product?.material?.name} />}
                            <div className={'flex  flex-col'}>
                                {product?.coating && (
                                    <div>
                                        <Property label="Coating" value={product?.coating?.name} />
                                    </div>
                                )}
                                {product?.finish && (
                                    <div>
                                        <Property label="Finish" value={product?.finish?.name} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            },
        },

        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 100,
            align: 'center',
            render: (t, record) => {
                return (
                    <InputNumber
                        defaultValue={record.quantity}
                        min={1}
                        onChange={(v: any) => {
                            if (v) {
                                const _total = v * record.unit_price;

                                const total_after_discount =
                                    record.discount > 0 ? valueWithFullDiscount : parseFloat(_total.toFixed(2));

                                SalesOrderStore.updateCloneOrder({
                                    ...record,
                                    quantity: v,
                                    discount:
                                        total_after_discount == 0 ? parseFloat(_total.toFixed(2)) : record.discount,
                                    total: parseFloat(_total.toFixed(2)),
                                    total_after_discount: total_after_discount,
                                });
                            }
                        }}
                    />
                );
            },
        },

        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            width: 140,
            align: 'center',
            render: (t, record) => {
                return (
                    <span>
                        <Price value={record.unit_price} />
                    </span>
                );
            },
        },
        {
            title: 'Apply 100% Discount',
            dataIndex: 'discount',
            width: 200,
            align: 'center',
            render: (t, record) => (
                <Checkbox
                    defaultChecked={record?.total_after_discount == 0.0 ? true : false}
                    onChange={e => {
                        if (e.target?.checked) {
                            const _total = record.quantity * record.unit_price;
                            SalesOrderStore.updateCloneOrder({
                                ...record,
                                discount: parseFloat(_total.toFixed(2)),
                                total_after_discount: valueWithFullDiscount,
                            });
                        } else {
                            SalesOrderStore.updateCloneOrder({
                                ...record,
                                discount: 0,
                                total_after_discount: record.total,
                            });
                        }
                    }}
                />
            ),
        },

        {
            title: 'Total Price',
            dataIndex: 'total',
            width: 140,
            align: 'center',
            render: (t, record) => {
                return (
                    <>
                        <span>
                            <Price value={record.total_after_discount} />
                        </span>
                    </>
                );
            },
        },

        {
            title: 'Action',
            dataIndex: 'actions',
            width: 140,
            align: 'center',
            render: (t, record) => {
                return (
                    <div
                        className={'cursor-pointer'}
                        style={{
                            color: 'red',
                        }}
                        onClick={() => {
                            SalesOrderStore.removeCloneOrderItem(record.id).finally(() => {
                                message.success('Line Item Was Removed!');
                            });
                        }}
                    >
                        X
                    </div>
                );
            },
        },
    ];

    const emptyTable = () => {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                    <div className="flex flex-col">
                        <span style={{ color: '#000' }}>There are currently no line items to be displayed.</span>
                        {SalesOrderStore?.order && SalesOrderStore?.order?.line_items?.length >= 1 && (
                            <div className="mt-2">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        SalesOrderStore.setCloneOrder();
                                    }}
                                >
                                    Add All The Line Items
                                </Button>
                            </div>
                        )}
                    </div>
                }
            />
        );
    };

    return (
        <Modal
            open={showCloneOrderModal}
            title="Order Clone Request"
            width={1400}
            destroyOnClose={true}
            onCancel={() => onClose()}
            // bodyStyle={{ overflowY: 'scroll', overflowX: 'auto', height: '60vh' }}
            footer={[
                <>
                    <div key={1} className="flex justify-between">
                        <Button
                            onClick={() => {
                                onClose();
                            }}
                            size="large"
                            loading={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            loading={loading}
                            disabled={order?.line_items?.length === 0}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </>,
            ]}
        >
            <p className=" mb-5">
                This will create a clone of Order <strong>#{order?.name}</strong>.
            </p>
            <p>Before modifying the line items, please select a type.</p>
            <span className="bold mr-1">Select the Type:</span>
            <Select
                defaultValue="Dual Source"
                size="small"
                className="mb-5"
                onSelect={(value: string) => {
                    setType(value);
                }}
            >
                <Select.Option value={'DS'}>Dual Source</Select.Option>
                <Select.Option value={'ADD'}>Addition</Select.Option>
                <Select.Option value={'FEDEX'}>FedEx</Select.Option>
            </Select>
            <p className="flex justify-center text-center">Please modify the line items below if necessary.</p>
            <Divider>
                <div className={'title'}> Line Items</div>
            </Divider>
            <Table<OrderLineItemProps>
                rowKey={'id'}
                className={'variation'}
                pagination={false}
                dataSource={order?.line_items}
                columns={columns}
                scroll={{ x: 1200, y: 400 }}
                locale={{ emptyText: emptyTable }}
            />
        </Modal>
    );
};

export default observer(CloneOrderModal);
