import { action, makeObservable, observable, runInAction } from 'mobx';
import { get } from '@partsbadger/utils';

export interface IPartList {
    id: number;
    created_by: number;
    created_time: string;
    quote: Quote;
    master_product: MasterProduct;
    material: Coating;
    finish: Coating;
    coating: Coating;
    quantity: number;
    unit_price: string;
    total_price: string;
}

export interface Coating {
    id: number;
    name: string;
}

export interface MasterProduct {
    id: number;
    name: string;
    step_file: StepFile | null;
    drawing_file: DrawingFile;
}

export interface DrawingFile {
    id: number;
    file: string;
}

export interface StepFile {
    id: number;
    image: null | string;
    file: string;
}

export interface Quote {
    id: number;
    name: string;
    stage: Stage;
    completed_at: string | null;
    design_engineer: DesignEngineer | null;
}

export interface DesignEngineer {
    id: number;
    first_name: string;
    last_name: string;
    fullname: string;
    email: string;
    is_active: boolean;
    biography: null;
    picture: null;
    thumbnail: null;
}

export enum Stage {
    Cancelled = 'Cancelled',
    Completed = 'Completed',
}

class _PartsStore {
    isLoading = true;
    entities: IPartList[] = [];

    pagination: {
        current: number;
        defaultPageSize: number;
        pageSize: number;
        total: number;
    } = {
        current: 1,
        defaultPageSize: 30,
        pageSize: 30,
        total: 0,
    };

    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            entities: observable,
            pagination: observable,

            // Actions
            getAll: action,
            // getById: action,
        });
    }

    async getAll(params: any) {
        this.isLoading = true;

        try {
            const data = await get(`/staff/v2/quoted_products/`, {
                params: params,
            });
            runInAction(() => {
                this.entities = data.results;
                this.pagination.current = params.page;
                this.pagination.total = data.count;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    async getByAccountId(accountId: number, params?: any) {
        this.isLoading = true;

        try {
            const data = await get(`/staff/v2/quoted-products/`, {
                params: {
                    ...params,
                    quote__account: accountId,
                },
            });
            runInAction(() => {
                this.entities = data.results;
                this.pagination.current = 1;
                this.pagination.total = data.count;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
}

export const PartsStore = new _PartsStore();
