import React from 'react';
import { Card, Row, Col, Badge, Divider, Progress, Image, Button, List, Tag, Statistic } from 'antd';
import { IProductionProduct } from '../../stores/ProductionProductStore';
import { ArrowRightOutlined, FileOutlined, FilePdfOutlined } from '@ant-design/icons';

import { Typography } from 'antd';
import QuantityDisplay from './QuantityDisplay';
const { Text } = Typography;

interface ProductionInfoCardProps {
    product: IProductionProduct;
}

const ProductionProductBasicInformation: React.FC<ProductionInfoCardProps> = ({ product }) => {
    return (
        <Card
            style={{ width: '100%' }}
            title={
                <div>
                    <Text strong>
                        Ship By: <ArrowRightOutlined /> {product.required_partner_ship_date}
                    </Text>
                    {product.is_delayed && <Tag color="warning">Delayed</Tag>}
                </div>
            }
        >
            <Row gutter={[16, 16]} align="middle" justify="center">
                <Col xs={24} sm={8} md={6} style={{ textAlign: 'center' }}>
                    <Image
                        src={product.file_png ?? ''}
                        alt="No Image Available"
                        width={150}
                        height={150}
                        style={{ objectFit: 'cover', borderRadius: '8px' }}
                        preview={false}
                        placeholder={
                            <div style={{ width: 150, height: 150, backgroundColor: '#f0f0f0', borderRadius: '8px' }} />
                        }
                    />
                    <div style={{ marginTop: '16px' }}>
                        <Button type="link" href={product.file3d || undefined} disabled={!product.file3d}>
                            <FileOutlined /> STEP
                        </Button>
                        <Button type="link" href={product.file2d || undefined} disabled={!product.file2d}>
                            <FilePdfOutlined /> PDF
                        </Button>
                    </div>
                </Col>

                {/* Info Column */}
                <Col xs={24} sm={16} md={18}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <strong>Quantity:</strong>{' '}
                            <QuantityDisplay quantity={product.quantity} originalQuantity={product.original_quantity} />
                        </Col>
                        <Col span={12}>
                            <strong>Material Type:</strong> {product.material_type}
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <strong>Material:</strong> {product.material}
                        </Col>
                        <Col span={12}>
                            <strong>Surface Finish:</strong> {product.surface_finish || 'N/A'}
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <strong>Coating:</strong> {product.coating || 'N/A'}
                        </Col>
                    </Row>
                    {/*<Row gutter={[16, 16]}>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <strong>Unit Price:</strong> ${product.unit_price}*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <strong>Total Price:</strong> ${product.total_price}*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row gutter={[16, 16]}>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <strong>Estimated Vendor Price:</strong> ${product.estimated_vendor_price}*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <strong>Estimated Shipping Cost:</strong> ${product.estimated_shipping_cost}*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Col span={12}></Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

export default ProductionProductBasicInformation;
